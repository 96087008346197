<template>
  <div class="sign-detail-container">
    <div class="sign-detail-main">
      <div style="text-align: center; margin-top: 50px">
        <img
          style="width: 50%; height: 100%"
          :src="require('@/assets/images/live/sign.png')"
          alt=""
        />
      </div>

      <div class="sign-detail-title-second">会议报名及签到中心</div>
      <div class="sign-detail-content global-shadow">
        <div class="sign-icon">
          <img
            class="sign-icon-item"
            :src="require('@/assets/images/live/logo.jpg')"
            alt=""
          />
        </div>
        <div class="sign-detail-form">
          <div class="sign-detail-row">
            <div class="sign-detail-row-key">
              <span v-for="(text, index) in '姓名'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>
            <div class="sign-detail-row-value">
              <el-input
                v-model.trim="data.name"
                :disabled="isPP"
                placeholder="请输入姓名"
              >
                <!-- @input="onInput"
                @clear="doClear" -->
              </el-input>
            </div>
          </div>
          <div class="sign-detail-row">
            <div class="sign-detail-row-key">
              <!-- <span class="global-point">*</span> -->
              <span v-for="(text, index) in '省份'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>
            <div class="sign-detail-row-value">
              <el-select
                :disabled="isPP"
                v-model="data.province"
                placeholder="请选择省份"
                @change="changeProvince()"
              >
                <el-option
                  v-for="(item, index) in provinces"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="sign-detail-row">
            <div class="sign-detail-row-key">
              <!-- <span class="global-point">*</span> -->
              <span v-for="(text, index) in '城市'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>
            <div class="sign-detail-row-value">
              <el-select
                :disabled="isPP"
                v-model="data.city"
                @change="changeCity"
                placeholder="请选择城市"
              >
                <el-option
                  v-for="(item, index) in citys"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="sign-detail-row">
            <div class="sign-detail-row-key">
              <!-- <span class="global-point">*</span> -->
              <span v-for="(text, index) in '医院'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>
            <div class="sign-detail-row-value">
              <el-select
                :disabled="isPP"
                v-model="data.hospital"
                filterable
                allow-create
                placeholder="请选择医院"
              >
                <el-option
                  v-for="(item, index) in config.hospitalList"
                  :key="index"
                  :label="item.hospital"
                  :value="item.hospital"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="sign-detail-row">
            <div class="sign-detail-row-key">
              <!-- <span class="global-point">*</span> -->
              <span v-for="(text, index) in '科室'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>
            <div class="sign-detail-row-value">
              <el-select
                :disabled="isPP"
                v-model="data.department"
                filterable
                allow-create
                placeholder="请选择科室"
              >
                <el-option
                  v-for="(item, index) in departmentList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="sign-detail-row">
            <div class="sign-detail-row-key">
              <!-- <span class="global-point">*</span> -->
              <span v-for="(text, index) in '手机号'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>
            <div class="sign-detail-row-value">
              <el-input
                v-model.trim="data.phone"
                @input="changePhone"
                placeholder="请输入手机号"
              >
              </el-input>
            </div>
          </div>
          <div class="sign-detail-row">
            <div class="sign-detail-row-key">
              <!-- <span class="global-point">*</span> -->
              <span v-for="(text, index) in '职务'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>

            <div class="sign-detail-row-value">
              <el-select
                v-model="data.job_name"
                placeholder="请选择职务"
                :disabled="isPP"
              >
                <el-option
                  v-for="(item, index) in config.jobs"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="sign-detail-row">
            <div class="sign-detail-row-key">
              <span v-for="(text, index) in '职称资料'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>
            <div class="sign-detail-row-value">
              <div class="signIn_info_image-select">
                <div class="identity_proof" v-if="data.identity_proof">
                  <div
                    :class="isPP ? 'identity_proof' : ''"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                    @click="selectFileImg('identity_proof')"
                  >
                    已上传 <el-icon><SuccessFilled /></el-icon>
                  </div>
                  <!-- <div
                    style="text-decoration: underline; padding-right: 12px"
                  
                  >
                    更新
                  </div> -->
                </div>
                <!-- class="identity_proof" -->
                <span
                  style="padding-left: 12px"
                  v-else
                  @click="selectFileImg('identity_proof')"
                >
                  点击上传职称资料
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="sign-detail-btn-group global-center">
          <CustomButton class="sign-detail-btn" @click="doSign" round
            >进入直播</CustomButton
          >
        </div>
      </div>
    </div>
    <div class="sign-back" @click="goBack">返回重新匹配</div>
    <input
      type="file"
      v-show="false"
      v-if="resetFile"
      @change="changeFileImg"
      ref="selectFileImg"
    />
  </div>
</template>

<script>
import weixin from "@/common/weixin";
import uploads from "@/common/uploads";
import CustomButton from "@/components/unit/CustomButton.vue";
import ItemScroll from "@/components/unit/ItemScroll";
import department from "@/common/department.json";
import { SuccessFilled } from "@element-plus/icons";
export default {
  name: "Sign",
  components: {
    CustomButton,
    ItemScroll,
    SuccessFilled,
  },
  data() {
    return {
      isShowDoc: false,
      identity_proof: "",
      resetFile: true,
      data: {
        meet_id: 0,
        device_code: "",
        name: "",
        province: "",
        city: "",
        hospital: "",
        device: "",
        os_version: "",
        width: "",
        height: "",
        department: "",
        job_name: "",
        phone: "",
        identity_proof: "",
      },
      isSign: false,
      config: {
        citys: [],
        hospitalList: [],
        jobs: ["主任医师", "副主任医师", "主治医师", "医师（住院医师）"],
      },
      query: {},
      notSign: false,
      intelligent_matching: 0, //标记当前这个观众的来源 来源类型，0 自己填，1 观众库
      comparativeData: {}, //匹配对比数据
      comparativeKey: [
        "name",
        "province",
        "city",
        "hospital",
        "department",
        "job_name",
        "identity_proof",
      ], //匹配对比数据
      departmentList: department,
      isPP: 0, //是否匹配
    };
  },
  computed: {
    provinces() {
      return this.config.citys.map((item) => item.name);
    },
    citys() {
      if (!this.data.province) return [];
      let index = this.provinces.indexOf(this.data.province);
      if (index == -1) return [];

      return this.config.citys[index].sub.map((item) => item.name);
    },
  },
  async created() {
    this.config.citys = await this.$tools.getCityConfig();
    this.query = this.$route.query;

    this.data = this.$tools.getStorage("signData").data;
    this.isPP = this.$tools.getStorage("signData").isPP;
    // this.identity_proof = this.data.identity_proof.split("/").pop();
    // let data = {
    //   ...this.$tools.getSystemInfo(),
    //   id: this.query.meetId,
    // };
    // let url = this.$tools.getURL(this.$urls.live.info, data);
    // try {
    //   let response = await this.$axios.get(url);
    //   if (this.query.loginType == 1) {
    //     if (response.data.inspection_valid == 0) {
    //       this.$tips.error(
    //         { text: "该会议暂不支持飞检，请联系运营人员" },
    //         60 * 1000
    //       );
    //       return;
    //     }
    //     this.goToApp();
    //     return;
    //   }
    //   if (this.query.loginType == 2) {
    //     this.goToApp();
    //     return;
    //   }
    //   if (response.data && response.data.openid) {
    //     this.data = response.data;
    //     this.data.name = this.data.sign_name;
    //     this.identity_proof = this.data.identity_proof.split("/").pop();
    //     this.notSign = true;
    //     this.comparativeData = { ...response.data };
    //     this.intelligent_matching = response.data.intelligent_matching;
    //   } else {
    //     this.notSign = true;
    //   }
    // } catch (err) {
    //   console.log(err);
    //   this.notSign = true;
    // }
    this.loadHospital();
  },
  methods: {
    verifyData() {
      if (!this.data.name) {
        this.$tips.error({ text: "请输入姓名" });
        return false;
      }
      if (!this.data.province) {
        this.$tips.error({ text: "请选择省份" });
        return false;
      }
      if (!this.data.city) {
        this.$tips.error({ text: "请选择城市" });
        return false;
      }
      if (!this.data.hospital) {
        this.$tips.error({ text: "请输入医院" });
        return false;
      }
      if (!this.data.department) {
        this.$tips.error({ text: "请输入科室" });
        return false;
      }
      if (!this.data.phone) {
        this.$tips.error({ text: "请输入手机号" });
        return false;
      }
      if (!/^1[3-9]{1}\d{9}/.test(this.data.phone)) {
        this.$tips.error({ text: "请输入正确的手机号" });
        return false;
      }

      if (!this.data.job_name) {
        this.$tips.error({ text: "请选择职务" });
        return false;
      }
      if (!this.data.identity_proof) {
        this.$tips.error({ text: "请上传职称资料" });
        return false;
      }
      return true;
    },
    selectFileImg(key) {
      if (this.isPP) return;
      this.selectKey = key;

      this.$refs.selectFileImg.click();
    },
    //上传图片(不裁图)
    async changeFileImg(event) {
      let reg = "jpg|jpeg|png|PNG|JPG|JPEG";
      let file = event.target.files[0];
      if (!file) return;
      let RegObj = new RegExp(`^(${reg})$`);
      let type = file.name.split(".").slice(-1);
      if (!RegObj.test(type)) {
        this.$tips.error({ text: `类型必须是${reg.replace(/\|/g, ",")}` });
        return "";
      }
      this.resetFile = false;
      let loading = this.$tips.loading();
      this.$nextTick(() => {
        this.resetFile = true;
      });

      try {
        let res = await uploads.uploadBase64({
          file: await this.$tools.compressImage(file, true),
        });
        loading.close();
        this.data[this.selectKey] = res;

        // this.identity_proof = res.split("/").pop();
      } catch (err) {
        loading.close();
      }
    },
    changePhone() {
      this.data.phone = this.data.phone.replace(/\D/g, "").slice(0, 11);
    },
    changeProvince() {
      this.data.city = "";
      this.data.hospital = "";
    },
    changeCity() {
      this.loadHospital();
      this.data.hospital = "";
    },
    loadHospital() {
      let data = {
        provine: this.data.province,
        city: this.data.city,
      };
      let url = this.$tools.getURL(this.$urls.hospital.hospitalList, data);
      this.$axios.get(url).then((res) => {
        this.config.hospitalList = res.data;
      });
    },

    //签到信息比较 是否有修改
    getComparativeData() {
      for (let index = 0; index < this.comparativeKey.length; index++) {
        const element = this.comparativeKey[index];
        if (this.comparativeData[element] != this.data[element]) {
          this.intelligent_matching = 0;
          break;
        } else {
          this.intelligent_matching = 1;
        }
      }
    },
    doSign() {
      if (this.comparativeData.name && this.intelligent_matching == 1) {
        this.getComparativeData();
      }

      if (!this.verifyData() || this.loading) return;
      this.loading = true;
      let data = {
        ...this.data,
        ...this.$tools.getSystemInfo(),
        meet_id: this.query.meetId,
        intelligent_matching: this.intelligent_matching,
      };
      data.height = String(data.height);
      data.width = String(data.width);
      let name = this.data.name ? encodeURIComponent(this.data.name) : "";
      let openid = `${this.$tools.getCookie("openid")}|${name}`;

      this.$axios
        .post(this.$urls.live.sign, data, { openid })
        // this.$axios.post(this.$urls.live.sign, data)
        .then((res) => {
          console.log(res);
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          this.goToApp(res.openid);
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    goToApp(tmpOpenid = "") {
      let query = this.$route.query;
      let name = this.data.name ? encodeURIComponent(this.data.name) : "";
      let openid = tmpOpenid || `${this.$tools.getCookie("openid")}|${name}`;
      query.openid = openid;
      query.type = 0;
      query.sign = 1;
      query.loginType =
        query.loginType == 0 || query.loginType == undefined ? 0 : 1;
      weixin.wx.ready(() => {
        let url = this.$tools.getURL("/pages/guest/common/index", query);
        weixin.wx.miniProgram.redirectTo({ url });
      });
    },

    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
.sign-detail-container {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  background-image: url("../../../assets/images/bg.png");
  background-size: cover;
  background-repeat: no-repeat;

  overflow: auto;
}

.sign-detail-content {
  // width:88%;
  // height:88%;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  border-radius: 8px;

  position: relative;
}

.sign-detail-main {
  position: relative;
  z-index: 2;
  flex: 1 0 0;
}

.sign-detail-form {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}

.sign-detail-btn-group {
  width: 100%;
  margin-top: 20px;
}

.sign-detail-btn {
  width: 225px;
}

.sign-detail-title {
  font-size: 30px;
  font-weight: 700;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  color: #f2674a;
}

.sign-detail-title-second {
  font-size: 18px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #f2674a;
}

.sign-icon {
  // padding-left:75px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.sign-icon-item {
  width: 45px;
  height: 45px;
}

.sign-detail-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 40px;
}

.sign-detail-row-key {
  width: 80px;
  font-size: 16px;
  /* text-align: right; */
  margin-right: 15px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}

.sign-detail-row-value {
  flex: 1 0 0;
  position: relative;
  height: 100%;
}

.sign-detail-row-value .el-input__inner {
  // border: none;
  background: #fff;
  width: 100%;
}
.sign-detail-row-value .el-input.is-disabled .el-input__inner {
  background: #f5f7fa;
}

.signIn_info_image-select {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #f26649;

  // padding: 0.25rem 1rem;
  border-bottom: 1px solid #ccc;
  height: 100%;
  align-items: left;
  justify-content: center;
  word-break: break-all;
}

.sign-detail-container .el-select {
  width: 100%;
}
.sign-detail-container .identity_proof {
  height: 32px;
  background: #f5f7fa;
  padding: 0 0 0 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sign-detail-container .identity_proof i {
  margin-left: 10px;
}
.sign-detail-container .sign-back {
  left: 50%;
  font-size: 14px;
  text-align: center;
  margin: 10px 0 20px 0;
  color: #635757;
  border-bottom: 1px solid #635757;
}
</style>
