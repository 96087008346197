<template>
  <div></div>
</template>

<script>
import weixin from "@/common/weixin";
import PopopInstance from "@/components/unit/PopupInstance";
export default {
  data: () => {
    return {
      isJump: false,
    };
  },
  created() {
    document.addEventListener("visibilitychange", this.visibilityInfo);
    if (this.$tools.isHarmony()) {
      this.isHarmony();
      return;
    }
    this.isWindows();
  },

  beforeUnmount() {
    console.log("我已经remove了");
    window.removeEventListener("visibilitychange", this.visibilityInfo);
  },
  methods: {
    visibilityInfo() {
      console.log(document.visibilityState);
      if (document.visibilityState === "hidden") {
        console.log("用户离开了当前页面");
      } else if (document.visibilityState === "visible") {
        console.log(this.isJump);
        if (this.isJump) {
          weixin.wx.closeWindow();
        }
      }
    },
    isHarmony() {
      let msg = {
        title: "温馨提示",
        html: `<div class="popup-content-main">检测到您的系统是鸿蒙系统，鸿蒙系统暂不支持微信小程序的直播功能</div>`,
        btngroup: { sure: "确认", close: "取消" },
        align: "center",
      };
      PopopInstance(msg)
        .then(() => {
          weixin.wx.closeWindow();
        })
        .catch(() => {
          weixin.wx.closeWindow();
        });
    },
    // 手机微信
    isWindows() {
      if (this.$tools.isWindows()) {
        let msg = {
          title: "温馨提示",
          html: `<div class="popup-content-main" style='margin: 10px 0'>请使用手机版微信进行访问</div>`,
          btngroup: { sure: "确认", close: "取消" },
          align: "center",
        };
        PopopInstance(msg)
          .then(() => {
            weixin.wx.closeWindow();
          })
          .catch(() => {
            weixin.wx.closeWindow();
          });
      } else {
        this.compareVersion();
      }
    },
    //微信版本
    async compareVersion() {
      let version = this.$tools.getVersion();
      let meetId = this.$route.query.meetId;
      let url = this.$tools.getURL(this.$urls.meet.getVersion, {
        version: version,
        role: 0, // 0 是观众 1 是专家
        openid: this.$tools.getCookie("openid") || "",
        meetId: meetId,
      });
      await this.$axios
        .get(url)
        .then((res) => {
          let compareVersion = this.$tools.compareVersion(
            version,
            res.data.version
          );
          console.log("compareVersion :>> ", compareVersion);
          if (compareVersion == -1) {
            let msg = {
              title: "温馨提示",
              html: `<div class="popup-content-main">您的微信版本过低，(版本号:${version})，请将微信更新至最新版本。</div>`,
              btngroup: { sure: "确认", close: "取消" },
              align: "center",
            };

            PopopInstance(msg)
              .then(() => {
                weixin.wx.closeWindow();
              })
              .catch(() => {
                weixin.wx.closeWindow();
              });
          } else {
            this.goToApp();
          }
        })
        .catch(() => {});
    },
    goToApp() {
      this.isJump = true;
      let query = this.$route.query;
      let text = encodeURIComponent(
        `meetId=${query.meetId}&projectId=${this.$config.projectId}`
      );
      if (query.loginType == 1 || query.loginType == 2) {
        text = encodeURIComponent(
          `meetId=${query.meetId}&projectId=${this.$config.projectId}&is_test=0&loginType=${query.loginType}`
        );
      }
      // let text = encodeURIComponent(query.query);
      let url = `weixin://dl/business/?appid=wx5068564463162e5d&path=pages/user/sign&query=${text}&env_version=${this.$config.env_version}`;
      console.log("url :>> ", url);
      window.location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>