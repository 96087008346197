<template>
  <div class="meetItem_container">
    <div class="meetItem_title_date">
      <div style="display: flex; justify-content: space-between">
        会议时间：{{ item.meet_start_time.slice(0, -3) }}

        <!-- 小于一小时 且审核没通过 灰色 -->
        <CustomButton
          :class="{
            disableButton: item.check_status != 30,
          }"
          class="CustomButton copyButton"
          @click="getPoster(item)"
          >分享/下载海报</CustomButton
        >
      </div>
      <div style="display: flex">
        会议讲题：
        <div class="meet-title">
          {{ item.meet_topics }}
        </div>
      </div>
    </div>
    <div></div>
    <div
      class="reason"
      v-if="item.audit_status == 30 || item.audit_status == 50"
    >
      <div>反馈日期：{{ item.audit_time.substring(0, 11) }}</div>
      <div>失败理由：{{ item.audit_reason }}</div>
    </div>
    <div class="reason" v-if="item.cancel_status == 30">
      <div>反馈日期：{{ item.check_cancel_time.substring(0, 11) }}</div>
      <div>失败理由：{{ item.check_cancel_reason }}</div>
    </div>

    <div
      class="reason"
      v-if="item.check_status == 20 || item.check_status == 40"
    >
      反馈原因 ： {{ item.check_reason }}
    </div>
    <div class="reason" v-if="item.audit_status == 25 && false">
      <div>
        反馈日期：{{ item.sign_time.substring(0, 11) }}
        &nbsp;&nbsp; 基金会审核中
      </div>
    </div>
    <div class="reason" v-if="item.sign_name && item.pay_status == 0">
      <div>
        反馈日期：{{ item.sign_time.substring(0, 11) }}
        <div>已签署协议，基金会正在审核支付中</div>
        <!-- （预计60天内支付） -->
      </div>
    </div>
    <div class="reason" v-if="item.pay_status">
      <div>付款日期：{{ item.pay_date.substring(0, 11) }}</div>
      <div v-if="item.pay_status == 2">失败理由：{{ item.pay_reason }}</div>
      <div v-if="item.pay_status == 1">
        付款单位：中关村精准医学基金会{{ item?.agreement_company_name }}
      </div>
    </div>
    <div class="meetItem_group_btn">
      <div style="display: flex">
        <CustomButton
          style="margin-right: 0.1rem"
          class="CustomButton"
          :class="{
            statusButton: item.status == 0,
            statusEndButton: item.status > 1,
          }"
          >{{ getStatusText(item) }}
        </CustomButton>
      </div>
      <div style="display: flex">
        <CustomButton class="CustomButton" @click="goToMeetInfo(item)"
          >详细信息</CustomButton
        >
        <!-- 志愿者  观看会议 医生 去开会  is_test  0 正式 1测试   type 1志愿者  医生0 -->
        <CustomButton
          v-if="
            item.status <= 1 &&
            (item.check_status == 10 || item.check_status > 20) &&
            type == 0
          "
          style="margin-left: 0.1rem"
          class="CustomButton endButton"
          :class="{
            disableButton: item.finish_test,
          }"
          @click="goToMeet(item, type, 1)"
          >测试会议
          <!-- {{ type == 1 ? "观看会议" : "去开会" }} -->
        </CustomButton>
        <CustomButton
          v-if="
            item.status <= 1 &&
            (item.check_status == 10 || item.check_status > 20) &&
            type == 0
          "
          style="margin-left: 0.1rem"
          class="CustomButton endButton"
          @click="goToMeet(item, type, 0)"
          >正式会议
          <!-- {{ type == 1 ? "观看会议" : "去开会" }} -->
        </CustomButton>
        <!-- 志愿者  观看会议 医生 去开会  is_test  0 正式 1测试   type 1志愿者  医生0 -->
        <CustomButton
          v-if="
            item.status <= 1 &&
            (item.check_status == 10 || item.check_status > 20) &&
            type == 1
          "
          style="margin-left: 0.1rem"
          class="CustomButton endButton"
          @click="goToMeet(item, type, item.is_test)"
          >进入会议
          <!-- {{ type == 1 ? "观看会议" : "去开会" }} -->
        </CustomButton>
        <!-- <CustomButton
          v-if="item.audit_status == 25"
          style="margin-left: 0.1rem"
          class="CustomButton endButton"
          >基金会审核中</CustomButton
        > -->
        <!-- 人工审核通过 显示协议 -->
        <CustomButton
          style="margin-left: 0.1rem"
          @click="goToAgreement(item)"
          class="CustomButton endButton"
          v-if="item.audit_status >= 20 && item.audit_status != 30"
        >
          {{ getAgreementText(item) }}</CustomButton
        >
        <!-- 基金会审核成功 显示支付状态  我的支付状态-->
        <CustomButton
          v-if="item.audit_status == 40 && item.pay_status > 0"
          style="margin-left: 0.1rem"
          class="CustomButton endButton"
        >
          <!-- @click="payStatus(item)" -->
          {{ this.$config.types.pay[item.pay_status] }}
        </CustomButton>
      </div>
    </div>
    <el-dialog
      v-model="centerDialogVisible"
      title="温馨提示"
      width="90%"
      align-center
      center
    >
      <span>
        您的微信版本过低，(版本号:{{ version }})，请将微信更新至最新版本。
      </span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="centerDialogVisible = false">
            确认
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import CustomButton from "@/components/unit/CustomButton";
import PopopInstance from "@/components/unit/PopupInstance";

export default {
  name: "meetItem",
  components: {
    CustomButton,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
    type: {
      type: Number,
      default: 0,
    },
    userInfo: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      userLogin: {},
      meetingInfo: {},
      timer: null,
      goToMeetIng: false,
      centerDialogVisible: false,
      version: null,
      compareVersion: null,
      currLoading: null,
    };
  },
  created() {
    // this.showTestTip();
    this.userLogin =
      this.$tools.getStorage("userLogin", localStorage) ||
      this.$tools.getStorage("saleLogin", localStorage);
  },
  mounted() {},
  methods: {
    getStatusText(item) {
      // if (item.is_test) {
      //   return "测试中";
      // } else {
      //   if (item.courseware_type == 1) {
      //     if (item.courseware_audit_status == 0) {
      //       return "课件待审核";
      //     } else if (item.courseware_audit_status == 20) return "课件审核失败";
      //     else return this.$config.types.status[item.status];
      //   } else return this.$config.types.status[item.status];
      // }
      // if (item.cancel_status) {

      // }
      if (item.is_test == 1 && item.status == 1) return "测试进行中";
      if (item.cancel_status == 10) {
        return "会议取消申请中";
      } else if (item.cancel_status == 30) {
        return "会议取消失败";
      } else {
        return this.$config.types.status[item.status];
      }
    },
    getAgreementText(item) {
      if (this.type == 1) {
        if (!item.sign_name) {
          return "劳务签署提醒";
        } else {
          return "协议已签署";
        }
      } else {
        if (!item.sign_name) {
          return "签署协议";
        } else {
          return "协议已签署";
        }
      }
    },
    getPoster(item) {
      this.$tools.getDate(item.check_time);
      let msg = {
        title: "会议海报",
        html: `<div class="popup-content-main-poster"> <img  src="${item.meet_poster}" alt="" /></div>`,
        btngroup: {},
        align: "center",
      };
      //审核通过
      if (item.check_status == 30) {
        PopopInstance(msg)
          .then(() => {})
          .catch(() => {});

        return;
      } else {
        this.$tips.error({ text: "会议海报制作中，请1小时后点击下载" });
        return;
      }
    },
    doSure() {},
    goToMeetInfo() {
      this.$tools.setStorage("coursewareData", {});
      this.$tools.setStorage("meetData", {});
      this.$router.push({
        path: "/meetInfo",
        query: {
          user_id: this.$route.query.id || this.userInfo.id,
          meet_id: this.item.id,
          form: this.$route.path,
        },
      });
    },
    getInfo(type, is_test) {
      let url = this.$tools.getURL("", {
        type,
        is_test,
        id: this.item.id,
        openid: this.item.openid,
      });
      return url;
    },
    async goToMeet(item, type, is_test) {
      if (this.goToMeetIng) return;
      this.currLoading = this.$tips.loading();
      this.goToMeetIng = true;
      console.log("this.item :>> ", this.item);
      await this.getVersion(type, is_test, this.getInfo(type, is_test), item);
    },
    async goToMeetDoc(item, is_test) {
      if (!item || !item.id) {
        this.currLoading.close();
        return this.$tips.error({
          text: "系统错误，请退出系统，重新登录",
        });
      }

      if (this.userInfo.work_card_ocr_status === 0) {
        this.goToMeetIng = false;
        this.currLoading.close();
        return this.$tips.error({
          text: "专家职称信息审核中，审核通过后方可进入会议",
        });
      }
      if (this.userInfo.work_card_ocr_status === 2) {
        this.goToMeetIng = false;
        this.currLoading.close();
        return this.$tips.error({
          text: "专家职称信息审核失败，审核通过后方可进入会议",
        });
      }
      if (!this.userInfo.first_complete) {
        this.goToMeetIng = false;
        this.currLoading.close();
        return this.$tips.error({ text: "完善信息后，方可进入会议" });
      }

      if (
        this.userInfo.professional_qualifications_code == "" &&
        this.userInfo.military_hospital == 0 &&
        this.userInfo.first_complete == 1
      ) {
        this.currLoading.close();
        let msg = {
          title: "温馨提示",
          html: `<div class="popup-content-main">根据项目要求，请在【完善个人信息】中补充【执业资格证书编码】</div>`,
          btngroup: { sure: "去完善", close: "暂不入会" },
          align: "center",
        };
        PopopInstance(msg).then((res) => {
          this.currLoading.close();
          this.$router.push({
            path: "/personalInfo",
            query: {
              from: this.$route.path,
            },
          });
          return;
        });
        return;
      }

      if (
        this.item.courseware_audit_status == 0 &&
        this.item.courseware_type == 1
      ) {
        this.goToMeetIng = false;
        this.currLoading.close();
        return this.$tips.success({ text: "课件审核中，暂时无法开始会议" });
      }
      if (
        this.item.courseware_audit_status == 20 &&
        this.item.courseware_type == 1
      ) {
        this.goToMeetIng = false;
        this.currLoading.close();
        return this.$tips.success({
          text: "课件审核失败，请修改课件后重新上传",
        });
      }
      //会前 会后30 不入会
      //  .新-会前30分中以上可以进入会议-只能进行测试会议！

      //正式会议会议中 status=1 不进测试会议

      //物料未审核  不开正式
      if (
        is_test == 0 &&
        this.item.is_test == 0 &&
        this.item.check_status != 30
      ) {
        this.goToMeetIng = false;
        this.currLoading.close();
        return this.$tips.error({
          text: "会议海报制作中!",
        });
      }

      if (is_test == 0 && this.item.is_test == 1) {
        this.goToMeetIng = false;
        this.currLoading.close();
        return this.$tips.error({
          text: "您的测试会议尚未结束，请提醒专家点击下一页，直到结束直播!",
        });
      }
      //正式会议会议中 status=1 不进测试会议
      if (is_test == 1 && this.item.is_test == 0 && this.item.status == 1) {
        this.goToMeetIng = false;
        this.currLoading.close();
        return this.$tips.error({
          text: "正式会议进行中!",
        });
      }
      let start =
        this.$tools.getTime(this.item.meet_start_time) - 30 * 1000 * 60;
      let end = this.$tools.getTime(this.item.meet_start_time) + 30 * 1000 * 60;
      let newT = this.$tools.getTime(new Date());

      if (
        ((newT < start && is_test == 0) || (newT > end && is_test == 0)) &&
        this.item.status == 0
      ) {
        this.goToMeetIng = false;
        this.currLoading.close();
        return this.$tips.error({
          text: "当前时间不在预定会议时间内，请修改会议时间后再开展会议！",
        });
      }
      //未开测试 不进正式 finish_test = 1  开了
      if (is_test == 0 && this.item.finish_test == 0) {
        this.goToMeetIng = false;
        this.currLoading.close();
        return this.$tips.error({
          text: "为了保证您的直播效果，请先开展测试会议",
        });
      }
      console.log("object :>> ", navigator.userAgent, this.$tools.isHarmony());

      //鸿蒙系统
      if (this.$tools.isHarmony()) {
        this.isHarmony();
        this.goToMeetIng = false;
        this.currLoading.close();
        return;
      }
      //手机微信
      if (this.$tools.isWindows()) {
        this.isWindows();
        this.goToMeetIng = false;
        this.currLoading.close();
        return;
      }
      //微信版本
      if (this.compareVersion == -1) {
        this.getCompareVersion();
        this.goToMeetIng = false;
        this.currLoading.close();
        return;
      }

      // let content = {
      //   projectId: this.$config.projectId,
      //   openid: this.item.openid,
      //   meetId: this.item.id,
      // };
      // ENV_VERSION：要打开的小程序版本,正式版为release，体验版为trial，开发版为develop，仅在微信外打开时生效。注意：若不填写，则默认打开正式版小程序

      if (this.item.status == 0 && this.item.finish_test && is_test == 1) {
        this.currLoading.close();
        this.showTestTip();
        return;
      }

      let openid =
        this.item.openid ||
        this.$store.state.common.userInfo?.openid ||
        Math.floor(Math.random() * 100000000 + 100000000);
      let text = encodeURIComponent(
        `meetId=${this.item.id}&projectId=${this.$config.projectId}&is_test=${is_test}&openid=${openid}`
      );
      let path = `weixin://dl/business/?appid=wx5068564463162e5d&path=pages/user/home&query=${text}&ENV_VERSION=${this.$config.env_version}`;
      console.log("path :>> ", path);
      await this.savePathInfo(path);
      this.goToMeetIng = false;
      this.currLoading.close();
      window.location.href = path;
    },
    async goToMeetAdmin(item, is_test) {
      if (!item || !item.id) {
        this.currLoading.close();
        return this.$tips.error({
          text: "系统错误，请退出系统，重新登录",
        });
      }
      if (item.courseware_audit_status == 0 && item.courseware_type == 1) {
        this.goToMeetIng = false;
        this.currLoading.close();
        return this.$tips.success({ text: "课件审核中，暂时无法开始会议" });
      }
      if (item.courseware_audit_status == 20 && item.courseware_type == 1) {
        this.goToMeetIng = false;
        this.currLoading.close();
        return this.$tips.success({
          text: "课件审核失败，请修改课件后重新上传",
        });
      }
      // //正式会议会议中 status=1 不进测试会议

      // if (is_test == 0 && item.is_test == 1) {
      //   this.goToMeetIng = false;
      //   this.currLoading.close();
      //   return this.$tips.error({
      //     text: "您的测试会议尚未结束，请点击测试会议，点击下一页，直到结束直播!",
      //   });
      // }
      // //正式会议会议中 status=1 不进测试会议

      // if (is_test == 1 && item.is_test == 0 && item.status == 1) {
      //   this.goToMeetIng = false;
      //   this.currLoading.close();
      //   return this.$tips.error({
      //     text: "正式会议进行中!",
      //   });
      // }
      //鸿蒙系统
      if (this.$tools.isHarmony()) {
        this.isHarmony();
        this.goToMeetIng = false;
        this.currLoading.close();
        return;
      }
      //手机微信
      if (this.$tools.isWindows()) {
        this.isWindows();
        this.goToMeetIng = false;
        this.currLoading.close();
        return;
      }
      //微信版本
      if (this.compareVersion == -1) {
        this.getCompareVersion();
        this.goToMeetIng = false;
        this.currLoading.close();
        return;
      }
      // 观众进入小程序路由
      // 路由 /pages/user/sign?meetId=会议ID&projectId=配置的projectId
      let content = {
        meetId: item.id,
        projectId: this.$config.projectId,
        is_test: is_test,
      };
      let url = this.$tools.getURL("/pages/user/sign", content);
      let text = encodeURIComponent(
        `meetId=${item.id}&projectId=${this.$config.projectId}&is_test=${is_test}`
      );
      let path = `weixin://dl/business/?appid=wx5068564463162e5d&path=pages/user/sign&query=${text}&env_version=${this.$config.env_version}`;
      await this.savePathInfo(path);
      console.log(path);
      setTimeout(() => {
        this.goToMeetIng = false;
        this.currLoading.close();
        window.location.href = path;
      }, 3000);
    },
    async getVersion(type, is_test, path, item) {
      let url = this.$tools.getURL(this.$urls.meet.getVersion, {
        version: this.$tools.getVersion(),
        role: type == 1 ? 0 : 1,
        openid: this.item.openid,
        meetId: this.item.id,
        is_test,
        path: encodeURIComponent(path),
      });
      await this.$axios.get(url).then((res) => {
        this.version = res.data.version;
        //比较
        this.compareVersion = this.$tools.compareVersion(
          this.$tools.getVersion(),
          this.version
        );
        if (type == 1) {
          //观看
          this.goToMeetAdmin(item, is_test);
        } else {
          // 开会
          this.goToMeetDoc(item, is_test);
        }
      });
    },
    async savePathInfo(path) {
      let url = this.$tools.getURL(this.$urls.meet.savePath, {
        meetId: this.item.id,
        path: encodeURIComponent(path),
        other: encodeURIComponent(
          this.$tools.getURL("", {
            status: this.item.status,
          })
        ),
      });

      await this.$axios.get(url).then((res) => {});
    },
    getCompareVersion() {
      let msg = {
        title: "温馨提示",
        html: `<div class="popup-content-main">您的微信版本过低，(版本号:${this.version})，请将微信更新至最新版本。</div>`,
        btngroup: { sure: "确认", close: "取消" },
        align: "center",
      };
      PopopInstance(msg)
        .then(() => {
          return false;
        })
        .catch(() => {});
    },
    showTestTip() {
      let msg = {
        title: "温馨提示",
        html: `<div class="popup-content-main">请注意，测试会议仅作为熟悉内容，测试网络环境用，需召开正式会议，会议才算有效。\n\n该会议已召开测试会议，请确认是否继续召开测试会议?</div>`,
        btngroup: { sure: "确认", close: "取消" },
        align: "center",
      };
      PopopInstance(msg)
        .then(() => {
          this.goToApp(1);
          this.goToMeetIng = false;
        })
        .catch(() => {
          this.goToMeetIng = false;
        });
    },
    goToApp(is_test) {
      let openid =
        this.item.openid ||
        this.$store.state.common.userInfo?.openid ||
        Math.floor(Math.random() * 100000000 + 100000000);
      let text = encodeURIComponent(
        `meetId=${this.item.id}&projectId=${this.$config.projectId}&is_test=${is_test}&openid=${openid}`
      );
      let path = `weixin://dl/business/?appid=wx5068564463162e5d&path=pages/user/home&query=${text}&ENV_VERSION=${this.$config.env_version}`;
      this.goToMeetIng = false;
      this.currLoading.close();
      window.location.href = path;
    },
    isWindows() {
      let msg = {
        title: "温馨提示",
        html: `<div class="popup-content-main">请使用手机版微信进行访问</div>`,
        btngroup: { sure: "确认", close: "取消" },
        align: "center",
      };
      PopopInstance(msg)
        .then(() => {
          return false;
        })
        .catch(() => {});
    },
    isHarmony() {
      let msg = {
        title: "温馨提示",
        html: `<div class="popup-content-main">检测到您的系统是鸿蒙系统，鸿蒙系统暂不支持微信小程序的直播功能</div>`,
        btngroup: { sure: "确认", close: "取消" },
        align: "center",
      };
      PopopInstance(msg)
        .then(() => {
          return false;
        })
        .catch(() => {});
    },
    goToAgreement(item) {
      if (this.type == 1 && !item.sign_name) {
        let url = this.$tools.getURL(this.$urls.meet.signNotice, {
          id: item.id,
        });
        this.$tips.loading();
        this.$axios
          .get(url)
          .then(() => {
            this.$tips.loading().close();
            this.$tips.success({ text: "劳务签署提醒成功" });
          })
          .catch(() => {
            this.$tips.loading().close();
          });
      } else {
        this.$router.push({
          path: "/agreement",
          query: {
            meet_id: item.id,
            user_id: this.$route.query.id || this.userInfo.id,
            from: this.$route.path,
            type: 1,
            // ...this.$route.query,
          },
        });
      }
    },
    //我的支付状态
    payStatus(data) {
      console.log("object :>> ", data.status); //0 待支付 1 已支付 2 失败
      let msg = {
        title: "我的支付状态",
        html: `<div class="popup-content-main">已提交协议，基金会正在审核支付中。提交后预估14天到账，请留意短信及本平台微信推送。</div>`,
        btngroup: { sure: "确认" },
        align: "center",
      };
      if (data.status == 1) {
        msg.html = `<div class="popup-content-main">已支付\n支付时间：${data.doctor_pay_time}\n支付金额：${data.doctor_pay_money}\n支付方：${data.doctor_pay_name}</div>`;
      } else if (data.status == 2) {
        msg.html = `<div class="popup-content-main">支付失败\n反馈日期：${data.feed_back_time}\n失败原因：${data.feed_message_info}</div>`;
      }
      PopopInstance(msg)
        .then(() => {})
        .catch(() => {});
    },
  },
  handleErrorFn() {},
  handleLaunchFn() {},
  beforeUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
};
</script>

<style>
.meetItem_container {
  border-bottom: 1px solid #ccc;
}
.meetItem_title_date {
  border-left: 4px solid #f26649;
  font-weight: 400;
  font-size: 14px;
  color: #606060;
  line-height: 1.5;
  margin: 8px 0;
  padding: 4px;
  padding-right: 0;
}
.meetItem_title_date div {
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏溢出的内容 */
  text-overflow: ellipsis; /* 使用省略号表示文本被截断 */
}
.meetItem_title_date .meet-title {
  white-space: pre-wrap;
}
.meetItem_group_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.meetItem_container .CustomButton {
  width: auto !important;
  padding: 2px 6px;
  /* padding: 2px 6px; */
  height: 20px !important;
}
.meetItem_container .statusButton {
  background: #fcd6cc !important;
  color: #f26649 !important;
}
.meetItem_container .statusEndButton {
  background: #dee0e9 !important;
  color: #132460 !important;
}
.meetItem_container .endButton {
  background: #132460 !important;
}
.meetItem_container .copyButton {
  background: #fcd6cc !important;
  color: #f26649 !important;
  width: 100px !important;
}
.meetItem_container .disableButton {
  background: #ccc !important;
  color: #fff !important;
}
.meetItem_container .reason {
  color: red;
  font-size: 14px;
  margin-bottom: 8px;
}
.popup-content-main-poster {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0;
}
.popup-content-main-poster img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.popup_container .popup_main {
  max-height: 90%;
  overflow: auto;
}
</style>
