import { createRouter, createWebHashHistory, } from "vue-router"
import weixin from "@/common/weixin"
import tool from "@/common/tool"
import config from "@/common/config"
import { doctorRouterBefore, articleRouteBefore, liveRouterBefore, commonRouterBefore, salesRouteBefore, reportBefore, guestRouteBefore, adminRouterBefore } from './routerBefore'
const commonRoutes = [
  {
    path: "/QRjump",
    // meta: { title: "登录" },
    beforeEnter: commonRouterBefore,
    name: "QRjump",
    component: () => import(/* webpackChunkName: "QRjump", webpackPrefetch: true */ "@/components/page/home/QRjump.vue")
  },
  {
    path: "/login",
    // meta: { title: "登录" },
    beforeEnter: commonRouterBefore,
    name: "login",
    component: () => import(/* webpackChunkName: "login", webpackPrefetch: true */ "@/components/page/login/login.vue")
  },
  {
    path: "/doctorLogin",
    // meta: { title: "登录" },
    beforeEnter: commonRouterBefore,
    name: "doctorLogin",
    component: () => import(/* webpackChunkName: "doctorLogin", webpackPrefetch: true */ "@/components/page/login/doctorLogin.vue")
  },
  {
    path: "/adminLogin",
    // meta: { title: "管理员登录" },
    beforeEnter: commonRouterBefore,
    name: "adminLogin",
    component: () => import(/* webpackChunkName: "adminLogin", webpackPrefetch: true */ "@/components/page/login/adminLogin.vue")
  },
  {
    path: "/register",
    // meta: { title: "注册" },
    beforeEnter: commonRouterBefore,
    name: "register",
    component: () => import(/* webpackChunkName: "register", webpackPrefetch: true */ "@/components/page/login/register.vue")
  },
  {
    path: "/articleList",//文章列表
    // meta: { title: "飞派文献骨转移领域" },
    beforeEnter: articleRouteBefore,
    name: "articleList",
    component: () => import(/* webpackChunkName: "articleList", webpackPrefetch: true */ "@/components/page/article/articleList.vue")
  },
  {
    path: "/articleDetail",//文章详情
    // meta: { title: "飞派文献骨转移领域" },
    beforeEnter: articleRouteBefore,
    name: "articleDetail",
    component: () => import(/* webpackChunkName: "articleDetail", webpackPrefetch: true */ "@/components/page/article/articleDetail.vue")
  },
  {
    path: "/collectList",//收藏列表
    // meta: { title: "飞派文献骨转移领域" },
    beforeEnter: articleRouteBefore,
    name: "collectList",
    component: () => import(/* webpackChunkName: "collectList", webpackPrefetch: true */ "@/components/page/article/collectList.vue")
  },
  {
    path: "/ppt/detail",//文章详情
    // meta: { title: "飞派文献骨转移领域" },
    beforeEnter: articleRouteBefore,
    name: "pptDetail",
    component: () => import(/* webpackChunkName: "pptDetail", webpackPrefetch: true */ "@/components/page/article/pptDetail.vue")
  },
  {
    path: "/customArticleDetail",//文章详情
    // meta: { title: "飞派文献骨转移领域" },
    beforeEnter: articleRouteBefore,
    name: "customArticleDetail",
    component: () => import(/* webpackChunkName: "pptDetail", webpackPrefetch: true */ "@/components/page/article/customArticleDetail.vue")
  },
  {
    path: "/endLive",
    meta: { title: "会议结束提醒" },
    name: "endLive",
    component: () => import(/* webpackChunkName: "endLive", webpackPrefetch: true */ "@/components/page/login/endLive.vue")
  },
  {
    path: "/meetList",
    meta: { title: "会议列表" },
    beforeEnter: adminRouterBefore,
    name: "meetList",
    component: () => import(/* webpackChunkName: "meetList", webpackPrefetch: true */ "@/components/page/login/meetList.vue")
  },
  {
    path: "/endLive",
    meta: { title: "会议结束提醒" },
    name: "endLive",
    component: () => import(/* webpackChunkName: "endLive", webpackPrefetch: true */ "@/components/page/login/endLive.vue")
  },

]

const adminRoutes = [
  {
    path: "/meetInfo",
    meta: {},
    name: "meetInfo",
    beforeEnter: doctorRouterBefore,
    component: () => import(/* webpackChunkName: "meet", webpackPrefetch: true */ "@/components/page/meet/index.vue")
  },
  {
    path: "/doctorInfo",//专家首页
    // meta: { title: "飞派文献骨转移领域" },
    beforeEnter: doctorRouterBefore,
    name: "doctorInfo",
    component: () => import(/* webpackChunkName: "doctorInfo", webpackPrefetch: true */ "@/components/page/home/doctorInfo.vue")
  },
  {
    path: "/adminMeet",//首页
    // meta: { title: "飞派文献骨转移领域" },
    beforeEnter: salesRouteBefore,
    name: "adminMeet",
    component: () => import(/* webpackChunkName: "adminMeet", webpackPrefetch: true */ "@/components/page/home/adminMeet.vue")
  },
  {
    path: "/addDoctor",//新增专家
    // meta: { title: "飞派文献骨转移领域" },
    beforeEnter: salesRouteBefore,
    name: "addDoctor",
    component: () => import(/* webpackChunkName: "addDoctor", webpackPrefetch: true */ "@/components/page/home/addDoctor.vue")
  },
  {
    path: "/adminInfo",//志愿者首页
    // meta: { title: "飞派文献骨转移领域" },
    beforeEnter: salesRouteBefore,
    name: "adminInfo",
    component: () => import(/* webpackChunkName: "adminInfo", webpackPrefetch: true */ "@/components/page/home/adminInfo.vue")
  },
  {
    path: "/sign",//签到
    // meta: { title: "飞派文献骨转移领域" },
    beforeEnter: liveRouterBefore,
    name: "sign",
    component: () => import(/* webpackChunkName: "sign", webpackPrefetch: true */ "@/components/page/login/sign.vue")
  },
  {
    path: "/signDetail",//签到详情
    // meta: { title: "飞派文献骨转移领域" },
    // beforeEnter: liveRouterBefore,
    name: "signDetail",
    component: () => import(/* webpackChunkName: "signDetail", webpackPrefetch: true */ "@/components/page/login/signDetail.vue")
  },
  {
    path: "/live",//志愿者首页
    // meta: { title: "飞派文献骨转移领域" },
    beforeEnter: liveRouterBefore,
    name: "live",
    component: () => import(/* webpackChunkName: "live", webpackPrefetch: true */ "@/components/page/live/index.vue")
  },
]

const liveRoutes = [
  // {
  //   path: "/live",
  //   beforeEnter: liveRouterBefore,
  //   name: "live",
  //   component: () => import(/* webpackChunkName: "live", webpackPrefetch: true */ "@/components/page/live/live.vue")
  // },
]
const ignoreRoutes = [
  {
    path: "/page404",
    name: "page404",
    component: () => import(/* webpackChunkName: "page404", webpackPrefetch: true */ "@/components/common/page404.vue")
  },
  {
    path: "/jump",
    name: "jump",
    component: () => import(/* webpackChunkName: "jump", webpackPrefetch: true */ "@/components/main/jump.vue")
  },
  {
    path: "/permission",
    name: "permission",
    component: () => import(/* webpackChunkName: "permission", webpackPrefetch: true */ "@/components/main/permission.vue")
  },
  {
    path: "/agreement",
    meta: { title: "劳务协议" },
    name: "agreement",
    component: () => import(/* webpackChunkName: "agreement", webpackPrefetch: true */ "@/components/page/agreement/index.vue")
  },
  {
    path: "/courseware/list",
    meta: {},
    name: "list",
    beforeEnter: articleRouteBefore,
    component: () => import(/* webpackChunkName: "list", webpackPrefetch: true */ "@/components/page/courseware/list.vue")
  },
  {
    path: "/courseware/preview",
    meta: {},
    beforeEnter: articleRouteBefore,
    name: "preview",
    component: () => import(/* webpackChunkName: "preview", webpackPrefetch: true */ "@/components/page/courseware/preview.vue")
  },
  {
    path: "/courseware/detail",
    meta: {},
    beforeEnter: articleRouteBefore,
    name: "detail",
    component: () => import(/* webpackChunkName: "detail", webpackPrefetch: true */ "@/components/page/courseware/detail.vue")
  },

  {
    path: "/personalInfo",
    meta: {},
    name: "personalInfo",
    component: () => import(/* webpackChunkName: "detail", webpackPrefetch: true */ "@/components/page/home/personalInfo.vue")
  },
  {
    path: "/reportLogin",
    meta: { title: "报名系统-登录" },
    name: "reportLogin",
    component: () => import(/* webpackChunkName: "reportLogin", webpackPrefetch: true */ "@/components/page/hospital/login.vue")
  },
  {
    path: "/hospitalInfo",
    meta: { title: "报名系统-新增" }, beforeEnter: reportBefore,
    name: "hospitalInfo",
    component: () => import(/* webpackChunkName: "hospitalInfo", webpackPrefetch: true */ "@/components/page/hospital/hospitalInfo.vue")
  },
  {
    path: "/updateHospitalInfo",
    meta: { title: "报名系统-修改" }, beforeEnter: reportBefore,
    name: "updateHospitalInfo",
    component: () => import(/* webpackChunkName: "updateHospitalInfo", webpackPrefetch: true */ "@/components/page/hospital/updateHospitalInfo.vue")
  },
  {
    path: "/changeHospitalInfo",
    meta: { title: "报名系统-变更" }, beforeEnter: reportBefore,
    name: "changeHospitalInfo",
    component: () => import(/* webpackChunkName: "changeHospitalInfo", webpackPrefetch: true */ "@/components/page/hospital/changeHospitalInfo.vue")
  },
  {
    path: "/reportList",
    meta: { title: "报名系统-审批列表" }, beforeEnter: reportBefore,
    name: "reportList",
    component: () => import(/* webpackChunkName: "reportList", webpackPrefetch: true */ "@/components/page/hospital/reportList.vue")
  },
  {
    path: "/signUpInfo",
    meta: { title: "报名系统-报名详情" },
    beforeEnter: reportBefore,
    name: "signUpInfo",
    component: () => import(/* webpackChunkName: "signUpInfo", webpackPrefetch: true */ "@/components/page/hospital/signUpInfo.vue")
  },
  {
    path: "/equity",
    meta: { title: "报名系统-修改权益" }, beforeEnter: reportBefore,
    name: "equity",
    component: () => import(/* webpackChunkName: "equity", webpackPrefetch: true */ "@/components/page/hospital/equity.vue")
  },
]
const routes = [].concat(commonRoutes, ignoreRoutes, liveRoutes, adminRoutes);
// const routes =  []
const router = createRouter({
  history: createWebHashHistory(),
  routes
});

const ignorePages = ignoreRoutes.map(item => item.name) // 不需要验证的页面
router.beforeEach((to, from, next) => {
  tool.saveVersion()
  if (ignorePages.includes(to.name)) { next(); return; }
  // weixin.init()
  document.title = config.title
  if (to.meta.title !== undefined) {
    document.title = !to.meta.title ? config.title : `${config.title}-${to.meta.title}`
  }

  tool.isLocal() ? development(to, from, next) : produce(to, from, next);
})
async function development(to, from, next) {
  tool.setCookie('openid', 'o5Y9a6xx6g2hW3XiFJ9fVkdhrdDc21121')
  inSystem(to, from, next)
}
async function produce(to, from, next) {
  weixin.init()
  // if (to.query.openid) {
  //   tool.setCookie('openid', to.query.openid)
  // }
  let openid = tool.getCookie("openid") || "";
  console.log(to, openid)
  if (!openid) {
    next({ name: "jump", path: "/jump", query: { path: to.path, ...to.query } })
    return;
  }
  inSystem(to, from, next)
}
async function inSystem(to, from, next) {
  next()
}
export default router;
