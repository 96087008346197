<template>
  <div class="preview-courseware-container full-screen">
    <div class="preview-courseware-main">
      <div class="preview-courseware-title global-color">
        {{ data.zh_title || data.courseware_title }}
      </div>
      <div class="preview-courseware-date">{{ data.publish_date }}</div>
      <img
        @click="handleLike"
        class="preview-courseware-like"
        :src="
          require(`@/assets/img/${
            countInfo?.is_collect ? 'liking' : 'like'
          }.png`)
        "
        alt=""
      />
      <div class="preview-courseware-player">
        <div
          class="global-position full-screen"
          v-if="data.courseware?.length > 0"
        >
          <PPTPlayer
            :data="data.courseware"
            :cfg="{
              sliding: true,
              page: true,
              point: true,
            }"
          >
          </PPTPlayer>
        </div>
      </div>
      <div class="preview-courseware-button-group global-center">
        <CustomButton
          class="preview-courseware-button"
          @click="lessonPreparation"
          >一键备课</CustomButton
        >
      </div>
    </div>
    <div class="preview-courseware-footer">
      <IconButton
        title="周刊首页"
        :src="require('@/assets/images/icon/home.png')"
        @click="goBack"
      ></IconButton>
    </div>
    <Popup
      :msg="config.msg"
      v-if="config.isShow"
      @doClose="doClose"
      class="preview-courseware-popup"
    >
      <div class="preview_mate_doctor">
        <item-scroll ref="ItemScrollDoctor">
          <div style="">
            <div
              class="preview_mate_doctor_item"
              v-for="(item, i) in config.doctorList"
              :key="i"
              @click="selectExpert(item)"
            >
              {{ item.name }}-{{ item.hospital }}
            </div>
          </div>
        </item-scroll>
      </div>
    </Popup>
  </div>
</template>
<script>
import PPTPlayer from "@/components/player/PPTPlayer";
import CustomButton from "@/components/unit/CustomButton";
import IconButton from "@/components/unit/ButtonForIcon";
import Popup from "../../unit/Popup.vue";
import ItemScroll from "@/components/unit/ItemScroll";
import PopopInstance from "@/components/unit/PopupInstance";
import mixins from "@/components/unit/mixins";
export default {
  name: "preview-courseware",
  mixins: [mixins],
  components: {
    PPTPlayer,
    CustomButton,
    IconButton,
    Popup,
    ItemScroll,
  },
  props: {},
  data() {
    return {
      data: {},
      config: {
        doctorList: [],
        isShow: false,
        msg: {
          title: "请选择备课专家",
          align: "center",
          btngroup: { close: "取消" },
        },
      },
      countInfo: {},
      status: null,
    };
  },
  created() {
    this.initData();
    this.initCountInfo();
  },
  methods: {
    initData() {
      let url = this.$tools.getURL(this.$urls.literature.detail, {
        id: this.$route.query.coursewareId || this.$route.query.id,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.data = res.data;
        })
        .catch(() => {});
    },
    goBack() {
      this.doOperationRecord(this.data.id, 2);
      this.$router.push({
        path: "/articleList",
      });
    },
    // 一键备课   弹窗选专家
    async lessonPreparation() {
      let id = this.$store.state.common.userId;
      if (!id) {
        //销售
        this.config.isShow = true;
        this.loadDoctorData();
      } else {
        //专家本人
        await this.createMeet();
        if (!this.status) {
          this.$tools.setStorage("coursewareData", {
            literature_id: this.data.id,
            meet_topics: this.data.courseware_title,
            courseware_info: this.data.courseware,
            courseware_url:
              this.data.ppt_url_new || this.data.literature_pdf_url,
          });
          this.$router.push({
            path: "/meetInfo",
            query: {
              user_id: id,
              form: "/doctorInfo",
            },
          });
        }
      }
    },

    async createMeet(id) {
      //先完善信息
      let url = this.$tools.getURL(this.$urls.sale.userInfo, {
        id: this.$store.state.common.userId || id,
      });
      await this.$axios
        .get(url, { "user-id": this.$store.state.common.userId || id })
        .then((res) => {
          let doctorInfo = res.data;

          let msg = {
            title: "温馨提示",
            html: `<div class="popup-content-main" style='margin: 10px 0'>请先完善专家基本信息</div>`,
            btngroup: { sure: "去完善", close: "取消" },
            align: "center",
          };
          if (
            ([0, 2].includes(doctorInfo.work_card_ocr_status) &&
              doctorInfo.doctor_work_id_card) ||
            ([0, 2].includes(doctorInfo.work_card_ocr_status) &&
              doctorInfo.professional_panoramic &&
              doctorInfo.professional_person)
          ) {
            msg = {
              title: "温馨提示",
              html: `<div class="popup-content-main" style='margin: 10px 0'>您的职称资料尚未审核通过，待审核通过后方可预约会议</div>`,
              btngroup: { sure: "确认" },
              align: "center",
            };
            PopopInstance(msg).then(() => {});
            this.status = true;
            return;
          }
          if (
            doctorInfo.professional_qualifications_code == "" &&
            doctorInfo.military_hospital == 0 &&
            doctorInfo.first_complete == 1
          ) {
            msg = {
              title: "温馨提示",
              html: `<div class="popup-content-main">根据项目要求，请在【完善个人信息】中补充【执业资格证书编码】</div>`,
              btngroup: { sure: "去完善", close: "暂不备课" },
              align: "center",
            };
          }

          if (
            !doctorInfo.first_complete ||
            (doctorInfo.professional_qualifications_code == "" &&
              doctorInfo.military_hospital == 0)
          ) {
            PopopInstance(msg).then(() => {
              this.$router.push({
                path: "/personalInfo",
                query: {
                  id: this.$store.state.common.userId || id,
                  coursewareId: this.data.id,
                  from: this.$route.path,
                },
              });
            });
            this.status = true;
            return;
          }
          this.status = false;
        })
        .catch(() => {});
    },
    //加载销售绑定的专家
    async loadDoctorData() {
      let url = this.$tools.getURL(this.$urls.sale.home, {});
      await this.$axios
        .get(url)
        .then((res) => {
          this.config.doctorList = res.data.doctor_data;
          setTimeout(() => {
            this.$refs.ItemScrollDoctor.initScroll();
          }, 500);
        })
        .catch(() => {});
    },
    //选择专家
    async selectExpert(item) {
      this.config.isShow = false;
      await this.createMeet(item.id);

      if (!this.status) {
        this.$tools.setStorage("coursewareData", {
          literature_id: this.data.id,
          meet_topics: this.data.courseware_title,
          courseware_info: this.data.courseware,
          courseware_url: this.data.ppt_url_new || this.data.literature_pdf_url,
        });
        this.$router.push({
          path: "/meetInfo",
          query: {
            user_id: item.id,
            is_sale: this.$route.query.is_sale,
            form: "/adminInfo",
          },
        });
      }
    },
    doClose() {
      this.config.isShow = false;
    },
    initCountInfo() {
      let url = this.$tools.getURL(this.$urls.literature.countInfo, {
        id: this.$route.query.coursewareId || this.$route.query.id,
        type: this.$store.state.common.userInfo.id
          ? 0
          : this.$store.state.common.salesInfo.phone
          ? 1
          : 0,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.countInfo = res.data;
        })
        .catch(() => {});
    },
    handleLike() {
      console.log(
        "    this.$store.state.common.userInfo.id || :>> ",
        this.$store.state.common,
        this.$store.state.common.salesInfo.phone
      );
      if (
        this.$store.state.common.userInfo.id ||
        this.$store.state.common.salesInfo.phone
      ) {
        let url = this.$tools.getURL(this.$urls.literature.collect, {
          id: this.$route.query.id,
          type: this.$store.state.common.userInfo.id
            ? 0
            : this.$store.state.common.salesInfo.phone
            ? 1
            : 0,
        });
        this.$axios
          .get(url)
          .then((res) => {
            console.log("res :>> ", res);
            this.$tips.success({ text: res.msg });
            this.countInfo.is_collect = this.countInfo.is_collect ? 0 : 1;
          })
          .catch(() => {});
      } else {
        this.$router.push({
          path: "/doctorLogin",
          query: {
            form: this.$route.path,
            id: this.$route.query.id,
          },
        });
      }
    },
  },
};
</script>
<style>
.preview-courseware-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 0 20px;
  /* align-items: center; */
}

.preview-courseware-title {
  padding: 0 40px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.preview-courseware-date {
  font-weight: 400;
  font-size: 13px;
  color: #606060;
  text-align: center;
  padding: 10px 0;
}

.preview-courseware-player {
  width: 100%;
  padding-bottom: 100%;
  /* 高度和宽度相等 */
  position: relative;
}

.preview-courseware-content {
}
.preview-courseware-button-group {
  padding: 20px 0;
}
.preview-courseware-button {
  width: 90px !important;
  font-size: 14px;
  padding: 5px;
}
.preview_mate_doctor {
  margin-top: 8px;
  width: 100%;
  height: 160px;
}
.preview_mate_doctor_item {
  width: 300px;
  padding-left: 30px;
  line-height: 2.5;
  border-bottom: 1px solid #ccc;
}
.preview_mate_doctor :last-child {
  border-bottom: none !important;
}
.preview-courseware-popup .customButton_container {
  width: 90px !important;
  font-size: 14px;
  padding: 12px;
}
.preview-courseware-main {
  position: relative;
}
.preview-courseware-like {
  width: 25px;
  position: absolute;
  right: 9px;
  top: -4px;
}
</style>