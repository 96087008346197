<template>
  <div class="meetList_container">
    <div class="title">今日会议列表</div>
    <div class="meetList-main">
      <div v-for="(item, index) in data" :key="index">
        <div class="meetItem_title_date">
          <div style="display: flex; justify-content: space-between">
            会议时间：
            {{ item.meet_start_time.slice(0, -3) }}
          </div>
          <div style="display: flex">
            会议讲题：
            <div class="meet-title">
              {{ item.meet_topics }}
            </div>
          </div>
          <div style="display: flex">
            会议专家：
            <div class="meet-title">
              {{ item.doctor_name }}
            </div>
          </div>
          <div style="display: flex">
            会议状态：
            <div class="meet-title">
              {{ getStatusText(item) }}
            </div>
          </div>
          <div style="display: flex">
            会议人数：
            <div class="meet-title">
              {{ item.status == 1 ? item.sign_count || 1 : item.sign_count }}
            </div>
          </div>

          <div style="display: flex; justify-content: space-between">
            会议时长：
            {{ $tools.secondsToHms(item.local_tm) }}
            <div>
              <CustomButton
                :class="{
                  disableButton: !(item.status == 1 && item.is_test == 0),
                }"
                class="CustomButton"
                @click="goToMeet(item)"
                style="margin-right: 10px"
                >进入直播间</CustomButton
              >
              <CustomButton
                class="CustomButton"
                @click="endTestMeet(item)"
                :class="{
                  disableButton: !(item.is_test == 1 && item.status == 1),
                }"
                >结束测试会议</CustomButton
              >
            </div>
          </div>
        </div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import PopopInstance from "@/components/unit/PopupInstance";
export default {
  name: "meetList",
  data() {
    return {
      data: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    getStatusText(item) {
      if (item.is_test == 1 && item.status == 1) return "测试进行中";
      if (item.cancel_status == 10) {
        return "会议取消申请中";
      } else if (item.cancel_status == 30) {
        return "会议取消失败";
      } else {
        return this.$config.types.status[item.status];
      }
    },
    async loadData() {
      let url = this.$tools.getURL(this.$urls.meet.newMeet, {});
      await this.$axios
        .get(url, { "user-id": this.$store.state.common.adminInfo?.user_id })
        .then((res) => {
          this.data = res.data;
          this.$nextTick(() => {});
        })
        .catch(() => {});

      // this.$axios.get(this)
    },
    async goToMeet(item) {
      let msg = {
        title: "温馨提示",
        html: `<div style='padding: 10px 0'> 当前直播间未开放</div>`,
        btngroup: { sure: "确认" },
        align: "center",
      };
      if (!(item.status == 1 && item.is_test == 0)) {
        PopopInstance(msg)
          .then(async () => {})
          .catch(() => {});
        return;
      }

      let currLoading = this.$tips.loading();
      // 观众进入小程序路由   市场部loginType=2
      // 路由 /pages/user/sign?meetId=会议ID&projectId=配置的projectId
      // let content = {
      //   meetId: item.id,
      //   projectId: this.$config.projectId,
      //   is_test: item.is_test,
      // };
      // let url = this.$tools.getURL("/pages/user/sign", content);
      let text = encodeURIComponent(
        `meetId=${item.id}&projectId=${this.$config.projectId}&is_test=${item.is_test}&loginType=2`
      );
      let path = `weixin://dl/business/?appid=wx5068564463162e5d&path=pages/user/sign&query=${text}&env_version=${this.$config.env_version}`;
      await this.savePathInfo(path);
      console.log(item, path);
      setTimeout(() => {
        currLoading.close();
        window.location.href = path;
      }, 3000);
    },
    async savePathInfo(item, path) {
      let url = this.$tools.getURL(this.$urls.meet.savePath, {
        meetId: item.id,
        path: encodeURIComponent(path),
        other: encodeURIComponent(
          this.$tools.getURL("", {
            status: item.status,
          })
        ),
      });
      await this.$axios.get(url).then((res) => {});
    },
    async endTestMeet(item) {
      let msg = {
        title: "温馨提示",
        html: `<div style='padding: 10px 0'> 结束测试会议将更新专家测试会议状态，请确认是否结束测试会议</div>`,
        btngroup: { sure: "确认" },
        align: "center",
      };
      if (!(item.is_test == 1 && item.status == 1)) {
        msg.html = `<div style='padding: 10px 0'>专家未在测试会议流程中</div>`;
        PopopInstance(msg)
          .then(async () => {})
          .catch(() => {});
        return;
      }
      PopopInstance(msg)
        .then(async () => {
          let url = this.$tools.getURL(this.$urls.meet.testEnd, {
            id: item.id,
          });
          await this.$axios
            .get(url, {
              "user-id": this.$store.state.common.adminInfo?.user_id,
            })
            .then((res) => {
              this.loadData();
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
  },
};
</script>

<style >
.meetList_container {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 10px;
}
.meetList-main {
}
.meetList_container .title {
  font-weight: bold;
  font-size: 14px;
  color: #f26649;
  margin-bottom: 20px;
}
.meetList_container .meetItem_title_date {
  border-left: 4px solid #f26649;
  font-weight: 400;
  font-size: 14px;
  color: #606060;
  line-height: 1.5;
  margin: 10px 0;
  padding: 4px;
  padding-right: 0;
}
.line {
  width: 100%;
  /* margin-top: 10px; */
  border-bottom: 1px solid #ccc;
}
.meetList_container .meetItem_title_date div {
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏溢出的内容 */
  text-overflow: ellipsis; /* 使用省略号表示文本被截断 */
}
.meetList_container .meetItem_title_date .meet-title {
  white-space: pre-wrap;
}
.meetList_container .CustomButton {
  background: #f26649 !important;
  color: #fff;
  width: auto !important;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 14px;
}
.meetList_container .disableButton {
  background: #ccc !important;
  color: #fff !important;
}
</style>