// 工具模块
import config from '@/common/config'
import store from "@/store"

let noScroll = function (e) {
}
const tool = {
  noScroll(e) { e.preventDefault() },
  setCookie(key, value, time = 24) {
    key = tool.mergeKey(key)
    var date = new Date();
    date.setTime(date.getTime() + (time * 60 * 60 * 1000));
    var expires = "expires=" + date.toGMTString();
    document.cookie = key + "=" + value + "; " + expires;
  },
  getCookie(key) {
    key = tool.mergeKey(key)
    var name = key + "=";
    var params = document.cookie.split(';');
    for (var i = 0; i < params.length; i++) {
      var item = params[i].trim();
      if (item.indexOf(name) == 0) return item.substring(name.length, item.length);
    }
    return "";
  },
  isCanSignName(doctorInfo) {
    console.log('doctorInfo :>> ', doctorInfo);
    let keys = ["phone", "id_card_number", 'id_card_front', 'id_card_reverse', "bank_name", "bank_province", "bank_city", "bank_name_point", "bank_number"]
    let isHave = true;
    for (let key of keys) {
      if (!doctorInfo[key]) {
        isHave = false;
        break;
      }
    }
    // 是否审核通过
    let isAudit = doctorInfo.id_card_ocr_status == 1
    return isHave && isAudit
  },
  setLocalCookie(key, value, time = 24) {
    key = tool.mergeKey(key)
    let data = {
      val: value,
      time: Date.now() + time * 60 * 60 * 1000
    }
    tool.setStorage(`${key}_cookie`, data)

  },
  removeTouch() {
    // console.log(getEventListeners)
    // let list = window.getEventListeners(window)
    // list.forEach(e=>{
    //   document.body.removeEventListener("touchmove",(e)=>{e.preventDefault()},false)
    // })
    document.body.removeEventListener("touchmove", noScroll, { passive: false })
  },

  getOpenid() {
    return tool.getCookie('openid') || ''
  },
  addTouch() {
    document.body.addEventListener('touchmove', noScroll, { passive: false })
  },

  isLogin() {
    return !!store.state.common.userInfo.name
  },
  isExecutor() {
    return !!store.state.common.executorInfo.name
  },

  getBackgroundColor() {
    let color = config.color
    return `background:${color}`
  },
  getColor(isText = true) {
    let color = config.color
    return isText ? `color:${color}` : color
  },
  getCfgColor() {
    let color = config.color
    return color
  },
  getBorderColor() {
    let color = config.color
    return `border-color:${color}`
  },
  getImageContent(path) {

    let images = JSON.parse(JSON.stringify(config.images || {}))
    // console.log(Object.keys(images).length, 22222223)
    if (Object.keys(images).length == 0) return ""
    let keys = path.split('.')
    // console.log(keys)
    let item = { ...images };
    for (let key of keys) {
      item = item[key] || ""
      // console.log(item, 33333333333333, key)
      if (typeof item == "string") return item
    }
    return ""
  },
  getImageBg(path) {
    let url = tool.getImageContent(path)
    console.log(path, url, "222222222222222222222")
    return `background: url('${url}');background-size: 100% 100%;`
  },
  getLocalCookie(key) {
    key = tool.mergeKey(key)
    let data = tool.getStorage(`${key}_cookie`) || { val: '', time: 0 }
    if (Date.now() < data.time) {
      return data.val;
    } else {
      return '';
    }
  },
  setStorage(key, val, storage = sessionStorage) {
    key = tool.mergeKey(key)
    // storage = sessionStorage || localStorage
    let data = {
      value: val
    }

    storage.setItem(key, JSON.stringify(data));
  },
  getStorage(key, storage = sessionStorage) {
    key = tool.mergeKey(key)
    // storage = sessionStorage || localStorage
    let data = storage.getItem(key);
    if (!data) return data;
    data = JSON.parse(data);
    return data.value
  },
  mergeKey(key) {
    return `${config.prefix}${key}`
  },
  getURL(url, data = {}) {
    let index = 0;
    for (let key in data) {
      let _symbol = index++ === 0 ? '?' : '&';
      url += `${_symbol}${key}=${data[key]}`
    }
    return url;
  },
  // 获取当前日期
  getDate(time, format = "yyyy-MM-dd") {
    let date = tool.getFormatTime(time),
      dateItem = {
        yyyy: date.getFullYear(),
        MM: tool.getTimeText(date.getMonth() + 1),
        dd: tool.getTimeText(date.getDate()),
        hh: tool.getTimeText(date.getHours()),
        mm: tool.getTimeText(date.getMinutes()),
        ss: tool.getTimeText(date.getSeconds())
      };
    let text = format;
    for (let key in dateItem) {
      text = text.replace(key, dateItem[key])
    }
    return text;
  },

  isWx() {
    let userAgent = navigator.userAgent.toLowerCase()
    return userAgent.includes('micromessenger')
  },
  isHaveMeetPermission() {

  },

  getSystemInfo() {
    let app = navigator.appVersion;
    let leftIndex = app.indexOf('(');
    let rightIndex = app.indexOf(')');
    let text = app.substring(leftIndex + 1, rightIndex);
    text = text.split(";");
    var Mobile_Iphone = text[0];
    var Mobile_Android = text[2];
    var res = /Android/;
    var result = res.test(Mobile_Android);
    let data = {
      device: Mobile_Iphone,
      version: '',
      width: window.screen.width,
      height: window.screen.height,
    }
    console.log(Mobile_Iphone, text)
    switch (Mobile_Iphone) {
      case 'Linux':
        let device = ''
        if (result) {
          device = text[4]
          data.version = text[2]
        } else {
          device = Mobile_Android
          data.version = text[1]
        }
        data.device = device.split('Build')[0]
        break;
      case 'iPhone': ;
      case 'iPad':
        data.device = text[0];
        let version = text[1].match(/OS\s([\d\_]+)\slike/g);
        version = version ? version[0] : '';
        data.version = version.split(/\s/)[1] ? `ios ${version.split(/\s/)[1]}` : ''
        break;
      default:
        data.device = text[0]
        data.version = text.join(' ')
    }
    data.device = String(data.device).trim()
    data.version = String(data.version).trim().replace(/_/g, '.')
    console.log('data :>> ', data);
    return data;
  },
  isIos() {
    // Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Mobile Safari/537.36
    const u = navigator.userAgent;
    const isIos = /iphone/gi.test(u)
    return isIos
  },
  saveVersion() {
    let version = tool.getStorage("version", localStorage) || ""
    if (version != config.version) {
      tool.setCookie("openid", "")
      tool.setStorage("version", config.version, localStorage)
    }
  },

  // navigator.userAgent.match(/MicroMessenger\/\d+.\d+.\d/)[0].split("/")[1],Windows
  getVersion() {
    let versionText = navigator.userAgent.match(/MicroMessenger\/\d+.\d+.\d+/)
    console.log('object :>> ', versionText);
    let version = ""
    if (versionText) {
      version = versionText[0].split("/")?.[1]
    }
    return version || "0.0.0"
  },
  isWindows() {
    //false 是  true 不是
    return /windows/i.test(navigator.userAgent)
  },
  isHarmony() {
    //false 不是  true 是
    return /Harmony/i.test(navigator.userAgent)
  },
  // 当前版本，需要比较版本，，
  // 1 当前版本大于比较版本
  // 0 当前版本等于比较版本
  // -1 当前版本小于比较版本
  compareVersion(v1, v2) {
    console.log(v1, v2)
    v1 = v1.split('.')
    v2 = v2.split('.')
    const len = Math.max(v1.length, v2.length)

    while (v1.length < len) {
      v1.push('0')
    }
    while (v2.length < len) {
      v2.push('0')
    }

    for (let i = 0; i < len; i++) {
      const num1 = parseInt(v1[i])
      const num2 = parseInt(v2[i])

      if (num1 > num2) {
        return 1
      } else if (num1 < num2) {
        return -1
      }
    }

    return 0
  },
  getFormatDate(time) {
    let today = tool.getTime(tool.getDate())
    let currTime = tool.getTime(time)
    if (today < currTime) return tool.getDate(time, "hh:mm")
    if (today - 86400 * 1000 < currTime) return "昨天"
    if (today - 86400 * 1000 * 2 < currTime) return "前天"
    return tool.getDate(time)
  },
  download(file) {
    var a = document.createElement("a");
    a.target = "_blank";
    a.href = file;
    a.download = tool.getFileName(file);
    a.click();
  },
  getFileName(data) {
    let fileName = data.split("/").pop()
    return fileName
  },
  getTimeText(time) {
    time = String(time)
    return time[1] ? time : `0${time}`
  },
  // 获取上周日期
  getPrepWeek() {
    let date = new Date();
    let day = date.getDay();
    let curTime = date.getTime();
    let prepstartDay = curTime - (day + 7 - 1) * 24 * 60 * 60 * 1000;
    let prepEndDay = curTime - day * 24 * 60 * 60 * 1000;
    return {
      start_time: tool.getDate(prepstartDay),
      end_time: tool.getDate(prepEndDay)
    }
  },
  // 获取上个月日期
  getPrepMonth() {
    let date = new Date();
    let day = date.getDate();
    let curTime = date.getTime();
    let prepEndDay = curTime - day * 24 * 60 * 60 * 1000;
    let preDate = new Date(prepEndDay);
    let preTime = preDate.getTime();
    let preYear = preDate.getFullYear();
    let preMonth = preDate.getMonth() + 1;
    let preCountDay = new Date(preYear, preMonth, 0).getDate()
    let prepstartDay = preTime - (preCountDay - 1) * 24 * 60 * 60 * 1000;
    return {
      start_time: tool.getDate(prepstartDay),
      end_time: tool.getDate(prepEndDay)
    }
  },
  // 获取时间戳
  getFormatTime(time = "") {
    if (typeof time == "object" && time != null) return time;
    if (typeof time == "string") time = time.replace(/-/g, "/");
    if (time == "None") return new Date()
    return time ? new Date(time) : new Date();
  },
  getTime(time) {
    let value = 0;
    try {
      let dealWithTime = tool.getFormatTime(time)
      value = dealWithTime.getTime();
    } catch (err) { console.log(err) }
    return value
  },
  getTimePhase(time) {
    time = time / 1000;
    if (time < 60) return 0;
    if (time < 60 * 60) return 1;
    if (time < 60 * 60 * 24) return 2;
    if (time > 60 * 60 * 24) return 3;
  },
  getShowTime(time, format = "", showLen = 0) {
    let defaultFormat = format || ["秒", "分钟", "小时", "天"];
    let text = "";
    time = time || 0;
    let phase = showLen ?? tool.getTimePhase(time)
    time = Math.floor(time / 1000)
    let dataList = [
      time % 60,
      Math.floor(time / 60) % 60,
      Math.floor(time / (60 * 60)) % 60,
      Math.floor(time / (24 * 60 * 60)) % 24
    ]
    for (let len = defaultFormat.length, index = 0; index < len; index++) {
      if (dataList[index] <= 0 && index >= phase) break;
      let content = tool.getTimeText(dataList[index])
      text = `${content}${defaultFormat[index]}${text}`
    }
    return text;
  },
  sortChinese(data, key) {
    let dataList = []
    dataList = key ? data.sort((params1, params2) => {
      return params1[key].localeCompare(params2[key], "zh")
    })
      :
      dataList = data.sort((params1, params2) => {
        return params1.localeCompare(params2, "zh")
      })
    return dataList;
  },
  // type 0 为文本 1 为聊天
  getChatContent(content = "", type = 0, voice_id = "", voice_length = 0) {
    return {
      content: content,
      content_type: type,
      voice_id,
      voice_length
    }
  },
  isLocal() {
    const localURL = /^(192\.168\.\d{1,3}\.\d{1,3})|(localhost)|(127.0.0.1)|(172\.16\.\d{1,3}\.\d{1,3})$/;
    let hostname = window.location.hostname;
    return localURL.test(hostname)
  },
  isExecutor() {
    return store.state.common.salesInfo.user_type == 2
  },
  dealWithText(content, count = 0) {
    const enterSeparator = "xxxxxx";
    content = content.replace(/\n/g, enterSeparator)
    let contentList = content.split(/[\s]/g)
    let resultList = contentList.map(val => {
      let list = val.match(/([^\w]+)|(\w+)/g) || [];
      list = list.map(x => {
        return /\w+/g.test(x) ? x : x.split("")
      })
      return list.flat()
    })
    resultList = resultList.flat();
    let result = "";
    let fieldReg = /[\u4e00-\u9fa5a-zA-Z0-9]+/
    let wordsCount = 0;
    // count = count || resultList.length;
    // count = count < resultList.length ? count : resultList.length
    for (let index in resultList) {
      let val = resultList[index]
      let separator = /\w+/g.test(val) && !/^\d+$/g.test(val) && index !== 0 ? " " : ""
      result += `${separator}${val}`
      if (fieldReg.test(val)) wordsCount += 1;
      if (count != 0 && wordsCount >= count) break;
    }
    let enterReg = new RegExp(enterSeparator, "g")
    return {
      content: result.replace(enterReg, "\n"),
      count: wordsCount
    }
  },
  getCityConfig: async function () {
    return new Promise((resolve) => {
      import('@/common/citys.json').then(data => {
        resolve(data.default)
      })
    })
  },
  getBankConfig: async function () {
    return new Promise((resolve) => {
      import('@/common/bank.json').then(data => {
        resolve(data.default)
      })
    })
  },
  verifyDoctorInfo(doctor, isCheckLabor = false) {
    let commonFields = [
      "professional_prove_img", // 职称资料
      "name", // 姓名
      "hospital", // 医院
      "department",// 科室

      "job_name", //
      "province", // 省份
      "city", // 城市
      "job", //行政职务
      "resume_academic", // resume_academic
      "professional_cover", // 简历图片
    ]
    let laborFields = [
      "phone",// 手机号
      "id_card_number", // 身份证照片 
      "id_card_front",// 身份证人像面
      "id_card_reverse", // 身份证国徽面
      "bank_province",// 银行卡信息
      "bank_city", // 银行卡城市
      "bank_name", // 银行
      "bank_name_point", // 支行
      "bank_number", // 银行卡号

    ]
    let checkFields = commonFields;
    if (isCheckLabor) {
      checkFields = checkFields.concat(laborFields)
    }
    if (doctor.is_finish) return ""
    return checkFields.find(key => !doctor[key] || doctor[key] == "待定")
  },
  getRandomNum() {
    return 1e18 + Math.random() * 1e18;
  },
  intToChinese(str) {
    str = str + "";
    var len = str.length - 1;
    var idxs = [
      "",
      "十",
      "百",
      "千",
      "万",
      "十",
      "百",
      "千",
      "亿",
      "十",
      "百",
      "千",
      "万",
      "十",
      "百",
      "千",
      "亿",
    ];
    var num = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
    return str.replace(/([1-9]|0+)/g, function ($, $1, idx, full) {
      var pos = 0;
      if ($1[0] != "0") {
        pos = len - idx;
        if (idx == 0 && $1[0] == 1 && idxs[len - idx] == "十") {
          return idxs[len - idx];
        }
        return num[$1[0]] + idxs[len - idx];
      } else {
        var left = len - idx;
        var right = len - idx + $1.length;
        if (Math.floor(right / 4) - Math.floor(left / 4) > 0) {
          pos = left - (left % 4);
        }
        if (pos) {
          return idxs[pos] + num[$1[0]];
        } else if (idx + $1.length >= len) {
          return "";
        } else {
          return num[$1[0]];
        }
      }
    });
  },

  compressImage: (file, convertToBase64 = false) => { // max=默认最大压缩到2M
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file); // 读出 base64
      reader.onloadend = ev => {
        let dataURL = ev.target.result;
        // 下面逻辑处理
        let file_mb = dataURL.length / (1024.0 * 1024);

        console.log(2, file_mb);

        let img = new Image();
        img.src = dataURL;
        if (file_mb <= 2) {
          return convertToBase64 ? resolve(ev.target.result) : resolve(file)
        }
        img.onload = () => {
          const canvas = document.createElement('canvas');
          if (!canvas) {
            return convertToBase64 ? resolve(ev.target.result) : resolve(file)
          }
          const ctx = canvas.getContext('2d');
          if (!ctx) {
            return convertToBase64 ? resolve(ev.target.result) : resolve(file)
          }
          const width = 375
          const height = img.height * (375 / img.width);
          canvas.width = width;
          canvas.height = height;
          ctx.fillStyle = '#fff';
          ctx.fillRect(0, 0, width, height);
          ctx.drawImage(img, 0, 0, width, height);
          const size = 0.4 //转换率，经测试0.4最好
          console.log(size, '-------')
          if (convertToBase64) {
            const base64 = canvas.toDataURL('image/jpeg', size)
            resolve(base64)
            return
          }
          canvas.toBlob(function (blob) {
            if (file?.name && blob) {
              blob.name = file.name
            }
            resolve(blob)
          }, 'image/jpeg', size)
        }
      }
    })
  },
  getDayName(date) {
    const days = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
    return days[new Date(date).getDay()];
  },

  secondsToHms(sec) {
    var hours = Math.floor(sec / 3600);
    var minutes = Math.floor((sec - (hours * 3600)) / 60);
    var seconds = sec % 60;

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return hours + ':' + minutes + ':' + seconds;
  }

}

export default tool;