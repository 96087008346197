<template>
  <div class="sign-custom-container" v-show="isTips" @click="closeShowTip">
    <div class="sign-custom-main">
      <div style="text-align: center; margin-top: 50px">
        <img
          style="width: 50%; height: 100%"
          :src="require('@/assets/images/live/sign.png')"
          alt=""
        />
      </div>

      <div class="sign-custom-title-second" v-show="notSign">
        会议报名及签到中心
      </div>
      <div class="sign-custom-content global-shadow" v-show="notSign">
        <div class="sign-icon">
          <img
            class="sign-icon-item"
            :src="require('@/assets/images/live/logo.jpg')"
            alt=""
          />
        </div>
        <div class="sign-form">
          <div class="sign-row">
            <div class="sign-row-key">
              <span v-for="(text, index) in '姓名'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>
            <div class="sign-row-value">
              <el-input
                v-model.trim="data.name"
                placeholder="请输入姓名或姓名首字母"
                @input="onInput"
                @clear="doClear"
              >
              </el-input>
            </div>
            <div v-if="isShowDoc" class="signIn_mate_block" @click.stop="">
              <div
                class="signIn_mate_doctor"
                :style="{
                  height: `${
                    config.AllDoctor.length == 0
                      ? '50px'
                      : config.AllDoctor.length == 1
                      ? '150'
                      : '300px'
                  }`,
                }"
              >
                <item-scroll ref="ItemScrollDoctor">
                  <div style="">
                    <div
                      class="signIn_mate_doctor_item"
                      v-for="(item, i) in config.AllDoctor"
                      :key="i"
                    >
                      <div>
                        <div>
                          <span
                            style="
                              font-size: 18px;
                              margin-right: 18px;
                              font-weight: bold;
                            "
                            >{{ item.name }}</span
                          >
                          <span>{{ item.job_name }}</span>
                        </div>

                        <div>
                          {{ item.province }}·{{ item.city }}（{{
                            item.department
                          }}）
                        </div>
                        <div>
                          {{ item.hospital }}
                        </div>
                      </div>
                      <div class="choice" @click="selectExpert(item)">选择</div>
                    </div>
                    <div
                      style="color: #b53c93"
                      class="signIn_mate_doctor_item"
                      @click="selectExpert()"
                    >
                      <div class="signIn_mate_doctor_btn">
                        {{
                          config.AllDoctor.length > 0
                            ? "以上均不是我的信息，我要新增"
                            : "暂无该医生信息，请新增"
                        }}
                      </div>
                    </div>
                  </div>
                </item-scroll>
              </div>
            </div>
          </div>
        </div>
        <div class="sign-history">
          <div class="sign-history-title">
            <div>历史登录名</div>
            <el-icon @click="delSignList"><DeleteFilled /></el-icon>
          </div>
          <div style="display: flex; flex-wrap: wrap">
            <div
              class="sign-history-item"
              v-for="(item, index) in signList"
              :key="item.id"
              @click="doHistory(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tips" v-show="notSign">
      <span style="font-weight: bold; font-size: 13px">温馨提示:</span>
      欢迎来到谈骨论经项目，本项目旨在为医药专业人员提供在线交流学习服务，进入系统需提供相关资质证明
    </div>
    <input
      type="file"
      v-show="false"
      v-if="resetFile"
      @change="changeFileImg"
      ref="selectFileImg"
    />
  </div>
</template>

<script>
import weixin from "@/common/weixin";
import ItemScroll from "@/components/unit/ItemScroll";
import department from "@/common/department.json";
import PopopInstance from "@/components/unit/PopupInstance";
import { DeleteFilled } from "@element-plus/icons";
export default {
  name: "Sign",
  components: {
    ItemScroll,
    DeleteFilled,
  },
  data() {
    return {
      isShowDoc: false,
      identity_proof: "",
      resetFile: true,
      data: {
        meet_id: 0,
        device_code: "",
        name: "",
        province: "",
        city: "",
        hospital: "",
        device: "",
        os_version: "",
        width: "",
        height: "",
        department: "",
        job_name: "",
        phone: "",
        identity_proof: "",
      },
      isSign: false,
      config: {
        citys: [],
        hospitalList: [],
        jobs: ["主任医师", "副主任医师", "主治医师", "医师（住院医师）"],
        msgManual: {
          title: "谈骨论经直播间声明",
          html: `<div class="popup-content-main-manual"><div style=" text-indent: 2em;"> 欢迎来到谈骨论经项目，本项目旨在为医药专业人员提供在线交流学习服务。</div><div style=" text-indent: 2em;">如您并非医药专业人员，不论您基于任何原因访问或浏览本项目直播间，您同意均不应参考本项目内容作为诊断、治疗、预防、康复、使用医疗产品或其他任何产品的建议或意见。对此，您应寻求执业医师及其他具备相应资质的专业人士意见并遵照医嘱。本项目所直播信息绝无意代替您自己的医学判断，并且本项目直播的任何观点、评论和其他内容亦无意作为可以信赖的建议。</div><div style=" text-indent: 2em;">我们郑重声明，因任何本项目访问者或任何获知本项目内容者基于对本项目材料的信赖所引起的任何责任与义务，都与本项目无关。您同意谈骨论经直播间内容将不对您使用和/或依赖本项目内容、产品、信息或者资讯导致的直接或间接损失承担任何责任。</div></div>`,
          btngroup: {
            sure: "我是专业医学人士",
            close: "我不是专业医学人士，退出",
            "btn-class": "manual",
            "main-class": "manual-main",
          },
          close: false,
          align: "center",
        },
        msgDel: {
          title: "温馨提示",
          html: `<div class="popup-content-main-manual">该操作将删除您所有的签到记录，确认删除吗?</div>`,
          btngroup: { sure: "确认", close: "取消", "btn-class": "delHistory" },
          align: "center",
          close: false,
        },
      },
      query: {},
      notSign: false,

      departmentList: department,
      isPP: 0, //是否匹配
      signList: [],
      isTips: true,
    };
  },

  async created() {
    this.config.citys = await this.$tools.getCityConfig();
    this.query = this.$route.query;
    let data = {
      ...this.$tools.getSystemInfo(),
      id: this.query.meetId,
    };
    let url = this.$tools.getURL(this.$urls.live.info, data);
    try {
      let response = await this.$axios.get(url);
      if (this.query.loginType == 1) {
        if (response.data.inspection_valid == 0) {
          this.$tips.error(
            { text: "该会议暂不支持飞检，请联系运营人员" },
            60 * 1000
          );
          return;
        }
        this.goToApp();
        return;
      }
      if (this.query.loginType == 2) {
        this.goToApp();
        return;
      }
      if (this.query.loginType == 3) {
        this.goToApp();
        return;
      }
      if (response.data && response.data.openid) {
        this.data = response.data;
        // this.data.name = this.data.sign_name;
        // this.notSign = true;
      } else {
        // this.notSign = true;
      }
    } catch (err) {
      console.log(err);
      // this.notSign = true;
    }
    if (!this.$tools.getStorage("signData")?.isTips) {
      PopopInstance(this.config.msgManual, false)
        .then(() => {
          this.notSign = true;
        })
        .catch(() => {
          this.isTips = false;
        });
    } else {
      this.notSign = true;
    }
    this.getSignList();
  },
  methods: {
    async getSignList() {
      let url = this.$tools.getURL(this.$urls.meet.getSignList);
      this.$axios
        .get(url)
        .then((res) => {
          this.signList = res.data;
        })
        .catch(() => {});
    },
    goToApp(tmpOpenid = "") {
      let query = this.$route.query;
      let name = this.data.sign_name
        ? encodeURIComponent(this.data.sign_name)
        : "";
      let openid = tmpOpenid || `${this.$tools.getCookie("openid")}|${name}`;
      query.openid = openid;
      query.type = 0;
      query.sign = 1;
      query.loginType =
        query.loginType == 0 || query.loginType == undefined ? 0 : query.loginType;
      weixin.wx.ready(() => {
        let url = this.$tools.getURL("/pages/guest/common/index", query);
        weixin.wx.miniProgram.redirectTo({ url });
      });
    },
    closeShowTip() {
      this.isShowDoc = false;
    },
    onInput(e) {
      if (e.length >= 2) {
        this.data = { name: e };
        this.intelligentMatching();
      }
      if (e.length == 1) {
        this.isShowDoc = false;
      }
      this.identity_proof = "";
      this.isPP = 0;
      if (e.length == 0) {
        this.doClear();
      }
    },
    doClear() {
      this.data = {};
      this.identity_proof = "";
      this.isShowDoc = false;
    },
    //智能匹配
    intelligentMatching() {
      if (this.data.name.length < 2) {
        this.$tips.error({ text: "请输入专家完整名字" });
        return false;
      }
      let url = this.$tools.getURL(this.$urls.live.matchSignDoctor, {
        doctor_name: this.data.name,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.config.AllDoctor = res.data;
          this.isShowDoc = res.type == "ok" ? true : false;
          this.$refs.ItemScrollDoctor.initScroll();
        })
        .catch((err) => {});
    },
    //选择专家(根据智能匹配或者变更专家弹出不同的框)
    async selectExpert(item) {
      if (item) {
        //匹配
        this.isPP = 1;

        this.data = { ...this.data, ...item };
        this.data.identity_proof = this.data.identity_proof.split(",")[0];
        this.data.is_match = 1;
      } else {
        //新增
        let name = this.data.name;
        this.data = { name };
        this.isPP = 0;
        this.data.is_match = 0;
        this.data.is_addition = 1;
        this.data.source = "谈骨论经";
      }
      this.data.phone = "";
      this.isShowDoc = false;
      this.$tools.setStorage("signData", {
        data: this.data,
        isPP: this.isPP,
        isTips: true,
      });
      this.$router.push({
        path: "/signDetail",
        query: { meetId: this.$route.query.meetId },
      });
    },
    //删除历史
    delSignList() {
      PopopInstance(this.config.msgDel, false).then(() => {
        let url = this.$tools.getURL(this.$urls.meet.delSignLog);
        this.$axios
          .delete(url)
          .then(() => {
            this.signList = [];
          })
          .catch(() => {});
      });
    },
    doHistory(item) {
      this.data.name = item.name;
      this.onInput(this.data.name);
    },
  },
};
</script>

<style lang="scss">
.sign-custom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  background-image: url("../../../assets/images/bg.png");
  background-size: 100% 100%;
}

.sign-custom-content {
  // width:88%;
  // height:88%;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  border-radius: 8px;
  margin-top: 15px;
  position: relative;
}

.sign-custom-main {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.sign-form {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}

.sign-custom-block {
  width: 100%;
}

.sign-custom-block input {
  border: none;
  font-size: 15px;
  padding-left: 0;
  padding-right: 0;
}

.sign-custom-block-key {
  font-weight: 700;
  flex: 80px 0 0;
  font-size: 16px;
  text-align: center;
}

.sign-custom-block-value {
  flex: 1 0 0;
  border-bottom: 1px solid #f1f1f1;
}

.sign-custom-btn-group {
  width: 100%;
  margin-top: 20px;
}

.sign-custom-btn {
  width: 225px;
}

.sign-custom-block {
  height: 90px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-custom-block:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.sign-custom-title {
  font-size: 30px;
  font-weight: 700;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  color: #f2674a;
}

.sign-custom-title-second {
  font-size: 18px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #f2674a;
}

.sign-icon {
  // padding-left:75px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.sign-icon-item {
  width: 45px;
  height: 45px;
}

.sign-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 40px;
  border-bottom: 1px solid #ccc;
  position: relative;
}

.sign-row-key {
  width: 80px;
  font-size: 16px;
  /* text-align: right; */
  margin-right: 15px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}

.sign-row-value {
  flex: 1 0 0;
  position: relative;
  height: 100%;
}

.sign-row-value .el-input__inner {
  // border: none;
  background: #fff;
  width: 100%;
}

.signIn_info_image-select {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #f26649;

  // padding: 0.25rem 1rem;
  border-bottom: 1px solid #ccc;
  height: 100%;
  align-items: left;
  justify-content: center;
  word-break: break-all;
}

.sign-custom-container .el-select {
  width: 100%;
}

.signIn_mate_block {
  width: 100%;
  padding: 4px;
  position: absolute;
  background: #fff;
  z-index: 999;
  top: 40px;
  right: 0;
  border: 1px solid #ccc;
  font-size: 14px;
}
.signIn_mate_input {
  border: 1px solid #f26649;
  border-bottom: none;
}
.signIn_mate_doctor {
  // margin-top: 8px;
  width: 100%;
  height: 160px;
}
.signIn_mate_doctor_item {
  padding-bottom: 10px;
  margin: 10px;
  line-height: 1.5;
  display: flex;
  min-height: 40px;
  justify-content: space-between;
  border-bottom: 1px dashed #ccc;
  align-items: start;
}
.signIn_mate_doctor_item:last-child {
  display: flex;
  justify-content: center;
  border-bottom: none;
}
.signIn_mate_doctor_item .choice {
  background: #f26649;
  color: #fff;
  padding: 6px 30px;
  height: 30px;
  border-radius: 4px;
}
.signIn_mate_doctor_btn {
  background: #a4adb3;
  color: #fff !important;
  border-radius: 2px;
  width: 100%;
  text-align: center;
  height: 24px;
  line-height: 24px;
}

.popup-content-main-manual {
  margin: 20px 10px;
  font-size: 14px;
}
.manual {
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}
.manual .customButton_container {
  border-radius: 4px;
  color: #fff;
  margin-bottom: 10px;
}
.manual .customButton_info {
  background: #a4adb3;
}
.manual .customButton_small {
  width: 200px !important;
  height: 30px;
}
.sign-history {
  margin-top: 10px;
  font-size: 16px;
}

.sign-history-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.sign-history-item {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 2px 10px;
  display: flex;
  justify-items: center;
  align-items: center;
  margin: 0 4px 6px 0;
  word-break: keep-all;
  font-size: 14px;
}

.delHistory .customButton_container {
  border-radius: 4px;
  color: #fff;
  margin-bottom: 10px;
  padding: 10px;
  width: 100px !important;
}
.delHistory .customButton_info {
  background: #a4adb3;
  color: #fff;
}
.tips {
  font-size: 13px;
  line-height: 1.5;
  color: #635757;
  position: fixed;
  bottom: 6px;
  padding: 0 20px;
  text-align: center;
}
.manual-main {
  color: #000;
  margin-top: 20px;
}
</style>
