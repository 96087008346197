<template>
  <div class="addDoctor_container">
    <div class="addDoctor_banner">
      <div class="addDoctor_banner_title">志愿者：{{ phone.slice(-4) }}</div>
    </div>
    <div class="addDoctor_main">
      <div style="display: flex; align-items: center">
        <img class="addDoctor_add" :src="require('@/assets/img/add.png')" />
        <span class="addDoctor_text" style="margin-right: 10px">新增专家</span>
      </div>
      <div class="addDoctor_main_row">
        <div class="addDoctor_tips" style="color: #f26649">*</div>
        <div class="addDoctor_main_row_key">姓名</div>
        <div class="addDoctor_main_row_value">
          <el-input
            v-model.trim="data.name"
            @input="inputField('name')"
            @clear="doClear"
            type="text"
            placeholder="请输入姓名"
          />
        </div>
        <CustomButton
          size="small"
          class="match_btn"
          @click="intelligentMatching"
          >智能匹配
        </CustomButton>

        <div v-if="isShowDoc" class="addDoctor_mate_block">
          <div class="addDoctor_remind">提醒:</div>
          <div class="addDoctor_remind">
            匹配后无需再完善专家已有信息，请慎重选择！
          </div>
          <div class="addDoctor_mate_doctor">
            <item-scroll ref="ItemScrollDoctor">
              <div style="">
                <div
                  class="addDoctor_mate_doctor_item"
                  v-for="(item, i) in config.AllDoctor"
                  :key="i"
                  @click="selectExpert(item)"
                >
                  {{ item.name }}-{{ item.hospital }}
                </div>
                <div
                  class="addDoctor_mate_doctor_item"
                  @click="selectExpert(item)"
                >
                  {{
                    config.AllDoctor.length > 0
                      ? "以上都不是，我要新增专家"
                      : "未匹配到该专家，请点击本提示自行新增专家"
                  }}
                </div>
              </div>
            </item-scroll>
          </div>
        </div>
      </div>
      <div class="addDoctor_main_row">
        <div class="addDoctor_tips" style="color: #f26649">*</div>
        <div class="addDoctor_main_row_key">手机号</div>
        <div class="addDoctor_main_row_value">
          <el-input
            v-model.trim="data.phone"
            @input="inputField('phone')"
            type="text"
            placeholder="请输入手机号"
          />
        </div>
      </div>
      <div class="addDoctor_main_row">
        <div class="addDoctor_tips" style="color: #f26649">*</div>
        <div class="addDoctor_main_row_key">省份</div>
        <div class="addDoctor_main_row_value">
          <el-select
            filterable
            v-model.trim="data.province"
            placeholder="请选择省份"
            @change="changeProvince()"
          >
            <el-option
              v-for="(item, index) in provinces"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="addDoctor_main_row">
        <div class="addDoctor_tips" style="color: #f26649">*</div>
        <div class="addDoctor_main_row_key">城市</div>
        <div class="addDoctor_main_row_value">
          <el-select
            filterable
            v-model.trim="data.city"
            placeholder="请选择城市"
            @change="changeCity"
          >
            <el-option
              v-for="(item, index) in citys"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="addDoctor_main_row">
        <div class="addDoctor_tips" style="color: #f26649">*</div>
        <div class="addDoctor_main_row_key">医院</div>
        <div class="addDoctor_main_row_value">
          <el-input
            v-model.trim="data.hospital"
            @input="inputField('hospital')"
            type="text"
            placeholder="请输入医院"
          />
        </div>
      </div>
      <div class="addDoctor_main_row">
        <div class="addDoctor_tips" style="color: #f26649">*</div>
        <div class="addDoctor_main_row_key">科室</div>
        <div class="addDoctor_main_row_value">
          <el-input
            v-model.trim="data.department"
            @input="inputField('department')"
            type="text"
            placeholder="请输入科室"
          />
        </div>
      </div>
      <div class="addDoctor_main_row">
        <div class="addDoctor_tips" style="color: #f26649"></div>
        <div class="addDoctor_main_row_key">邮箱</div>
        <div class="addDoctor_main_row_value">
          <el-input
            v-model.trim="data.email"
            @input="inputField('email')"
            type="text"
            placeholder="请输入邮箱"
          />
        </div>
      </div>
      <div class="addDoctor_main_row">
        <span style="color: #fff">*</span>
        <div class="addDoctor_main_row_key">会议数</div>
        <div class="addDoctor_main_row_value">
          <inputNum v-model="data.meet_count"></inputNum>
          <!-- <el-input-number
            v-model.trim="data.meet_count"
            :min="0"
            :max="3"
            :step="1"
            step-strictly
            size="small"
          >
          </el-input-number> -->
        </div>
      </div>
    </div>
    <div class="addDoctor_group_btn">
      <CustomButton size="small" @click="doCommit">确认添加 </CustomButton>
      <CustomButton
        size="small"
        style="margin-top: 14px"
        @click="$router.back()"
        >返回
      </CustomButton>
    </div>
  </div>
</template>

<script>
import ItemScroll from "@/components/unit/ItemScroll";
import CustomButton from "@/components/unit/CustomButton";
import inputNum from "@/components/unit/inputNum.vue";
export default {
  name: "addDoctor",
  components: {
    CustomButton,
    inputNum,
    ItemScroll,
  },
  data() {
    return {
      data: {
        name: "",
        phone: "",
        province: "",
        city: "",
        hospital: "",
        department: "",
        email: "",
        meet_count: 0,
      },
      config: { citys: [], AllDoctor: [] },
      phone: "",
      loading: false,
      isShowDoc: false,
    };
  },
  computed: {
    provinces() {
      return this.config.citys.map((item) => item.name);
    },
    citys() {
      if (!this.data.province) return [];
      let index = this.provinces.indexOf(this.data.province);
      if (index == -1) return [];
      return this.config.citys[index].sub.map((item) => item.name);
    },
  },
  async created() {
    this.config.citys = await this.$tools.getCityConfig();
    this.phone = this.$tools.getStorage("saleLogin", localStorage).phone;
  },
  mounted() {},
  methods: {
    changeProvince() {
      this.data.city = "";
      this.data.hospital = "";
    },
    changeCity() {
      // this.data.hospital = "";
      // this.loadHospital();
    },
    inputField(key) {
      switch (key) {
        case "name":
          if (this.data[key].length == 1) {
            this.isShowDoc = false;
          }
          if (this.data[key].length == 0) {
            this.doClear();
          }
      }
    },
    doClear() {
      this.data = {};
      this.isShowDoc = false;
    },
    checkData() {
      if (!this.data.name) {
        this.$tips.error({ text: "请输入姓名" });
        return false;
      }
      if (!this.data.phone) {
        this.$tips.error({ text: "请输入手机号" });
        return false;
      }
      if (!/^1[3-9]\d{9}$/.test(this.data.phone)) {
        this.$tips.error({ text: "请输入格式正确的手机号" });
        return false;
      }

      if (!this.data.province) {
        this.$tips.error({ text: "请选择省份" });
        return false;
      }
      if (!this.data.city) {
        this.$tips.error({ text: "请选择城市" });
        return false;
      }
      if (!this.data.hospital) {
        this.$tips.error({ text: "请输入医院" });
        return false;
      }
      if (!this.data.department) {
        this.$tips.error({ text: "请输入专家科室" });
        return false;
      }
      if (this.data.email) {
        if (!/^\w+[.-\w]+@[-\w]+\.\w+$/.test(this.data.email)) {
          this.$tips.error({ text: "邮箱格式不正确！" });
          return false;
        }
      }
      return true;
    },
    doCommit() {
      console.log("this.data :>> ", this.data);
      if (!this.checkData() || this.loading) return;
      this.loading = true;
      this.$axios
        .post(this.$urls.sale.addDoctor, this.data)
        .then((res) => {
          console.log(res);
          this.loading = false;
          this.$router.push({
            path: "/adminMeet",
          });
        })
        .catch((err) => {
          console.log("err :>> ", err);
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    //智能匹配
    intelligentMatching() {
      if (this.data.name.length < 2) {
        this.$tips.error({ text: "请输入专家完整名字" });
        return false;
      }
      let url = this.$tools.getURL(this.$urls.sale.getDoctor, {
        doctor_name: this.data.name,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.config.AllDoctor = res.data;
          this.isShowDoc = res.type == "ok" ? "true" : "false";
          setTimeout(() => {
            this.$refs.ItemScrollDoctor.initScroll();
          }, 500);
        })
        .catch((err) => {
          if (err.msg == "匹配医生次数已达上限" && err.type == "error") {
            this.isShowDoc = true;
          }
        });
    },
    //选择专家(根据智能匹配或者变更专家弹出不同的框)
    async selectExpert(item) {
      // this.doctorInfo = {};
      // this.doctorInfo.name = this.doctorName;
      if (item) {
        this.data = { ...this.data, ...item };
      } else {
        this.data = { name: this.data.name };
      }
      this.isShowDoc = false;
      // setTimeout(() => {
      //   this.$refs.ItemScroll.initScroll();
      // }, 500);
    },
  },
};
</script>

<style >
.addDoctor_container {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.addDoctor_banner {
  width: 100%;
  height: 258px;
  background-image: url("../../../assets/img/info_banner.png");
  background-size: 100% 100%;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  line-height: 35px;
}
.addDoctor_banner_title {
  padding: 110px 0 0 28px;
}
.addDoctor_main {
  background: #fff;
  border-radius: 10px;
  margin-top: -70px;
  padding: 24px;
}
.addDoctor_add {
  width: 30px;
}
.addDoctor_text {
  font-size: 14px;
  color: #f26649;
  line-height: 35px;
  margin-left: 10px;
}
.addDoctor_main_row {
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 26px;
  border-bottom: 1px dashed #ccc;
  height: 44px;
  position: relative;
}
.addDoctor_main_row_key {
  width: 70px;
  text-align-last: justify;
}
.addDoctor_main_row_value {
  margin-left: 40px;
  flex: 1 0 0;
  /* position: absolute;
  top: 0;
  left: 70px; */
}
.el-input__inner {
  border: none;
}
.addDoctor_group_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.addDoctor_group_btn .customButton_container {
  height: 31px;
  width: 100px;
}
.addDoctor_container .match_btn {
  width: 80px;
  border-radius: 4px;
  position: absolute;
  right: 0;
}
.addDoctor_mate_block {
  padding: 4px;
  position: absolute;
  background: #f0d2c9;
  z-index: 999;
  top: 50px;
  right: 0;
  border-radius: 8px;
}
.addDoctor_remind {
  color: #f26649;
  font-weight: bold;
  line-height: 1.8;
  padding-left: 8px;
}
.addDoctor_mate_doctor {
  margin-top: 8px;
  width: 100%;
  height: 160px;
}
.addDoctor_mate_doctor_item {
  width: 300px;
  padding-left: 30px;
  line-height: 2.5;
  border-bottom: 1px solid #ccc;
}
.addDoctor_mate_doctor :last-child {
  border-bottom: none !important;
}
.addDoctor_tips {
  width: 7px;
}
.addDoctor_container .el-input__wrapper,
.addDoctor_container .el-select__wrapper {
  border-radius: 18px;
  border: none;
  box-shadow: none !important;
}
</style>