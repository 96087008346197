<template>
  <div
    class="personalInfo_container"
    v-loading="loading"
    element-loading-text="数据上传中"
  >
    <!-- <ItemScroll
      class="personalInfo_itemScroll"
      ref="ItemScroll"
      :isClearMove="false"
    > -->
    <div>
      <div class="personalInfo_title">完善个人信息</div>
      <div style="padding: 0 16px">
        <div class="personalInfo_block_title">
          专家基本信息（提交后，职称资料将在1个工作日内审核完成）
        </div>
        <div class="update_personalInfo_block">
          <div class="personalInfo_block personalInfo_name">
            <div class="personalInfo_key"><span>*</span>姓名</div>
            <div class="global-input personalInfo_value">
              <el-input
                v-model.trim="doctorInfo.name"
                :disabled="true"
                placeholder="请输入姓名"
              ></el-input>
            </div>
          </div>
          <div class="personalInfo_block">
            <div class="personalInfo_key"><span>*</span>医院</div>
            <div class="global-input personalInfo_value">
              <el-input
                v-model.trim="doctorInfo.hospital"
                placeholder="请输入医院名称"
                @input="this.doctorInfo.professional_qualifications_code = ''"
              ></el-input>
            </div>
          </div>
          <div class="personalInfo_block">
            <div class="personalInfo_key"><span>*</span>专家科室</div>
            <div class="global-input personalInfo_value">
              <el-input
                v-model.trim="doctorInfo.department"
                placeholder="请输入专家科室"
              ></el-input>
            </div>
          </div>
          <!-- <div class="personalInfo_block">
            <div class="personalInfo_key"><span>*</span>主TA</div>
            <div class="global-input personalInfo_value">
              <el-input
                v-model.trim="doctorInfo.cancer_type"
                placeholder="请输入主TA"
              ></el-input>
            </div>
          </div> -->
          <div>
            <div class="personalInfo_block">
              <div class="personalInfo_key">
                <span>*</span>省份（示例：湖北）
              </div>
              <div class="global-input global_text_select personalInfo_value">
                <el-select
                  v-model="doctorInfo.province"
                  placeholder="请选择医生省份"
                  @change="changeProvince()"
                >
                  <el-option
                    v-for="(item, index) in provinces"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="personalInfo_block">
              <div class="personalInfo_key">
                <span>*</span>城市（示例：武汉）
              </div>
              <div class="global-input global_text_select personalInfo_value">
                <el-select
                  v-model="doctorInfo.city"
                  placeholder="请选择医生城市"
                >
                  <el-option
                    v-for="(item, index) in citys"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="personalInfo_block">
            <div class="personalInfo_key">
              <span>*</span>专家职称（示例：主治/副主任/主任医师）
            </div>
            <div class="global-input global_text_select personalInfo_value">
              <el-select
                v-model="doctorInfo.job_name"
                placeholder="请选择专家职称"
                @change="changeJobName"
              >
                <el-option
                  v-for="(item, index) in config.titles"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <!-- 专家职称证明 -->
          <div class="personalInfo_block">
            <div class="personalInfo_key">
              <span>*</span>
              专家职称证明
              <span style="color: #6f6f6f"
                >（可上传1-3张，请务必包含专家姓名、医院、科室、职称信息，否则无法审核通过）</span
              >
              <p
                style="color: #f26649"
                v-if="doctorInfo.work_card_ocr_status === 0"
              >
                人工审核中
              </p>
              <p
                style="color: #f26649"
                v-if="doctorInfo.work_card_ocr_status === 2"
              >
                人工审核反馈：{{ doctorInfo.work_card_ocr_reason }}
              </p>
            </div>
          </div>
          <div>
            <div class="personalInfo_block personalInfo_id_card_type">
              <div class="global-input personalInfo_value">
                <el-radio
                  v-model.number="doctorInfo.work_type"
                  :label="index"
                  v-for="(item, index) in config.workType"
                  :key="index"
                  @change="changeWorkType"
                  >{{ item }}</el-radio
                >
              </div>
            </div>
            <div class="personalInfo_block" v-if="doctorInfo.work_type == 0">
              <div class="personalInfo_key">医生ID卡</div>
              <div class="personalInfo_value global_container_center">
                <div
                  class="personalInfo_image-block"
                  @click="selectFileImg('doctor_work_id_card')"
                >
                  <CustomImage
                    :src="doctorInfo.doctor_work_id_card"
                    v-if="doctorInfo.doctor_work_id_card"
                    alt=""
                  />
                  <div class="personalInfo_image-select" v-else>
                    <el-icon v-show="!doctorInfo.doctor_work_id_card"
                      ><Plus
                    /></el-icon>
                    <span> 上传医生ID卡 </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="personalInfo_block" v-if="doctorInfo.work_type == 1">
              <div class="personalInfo_key">
                官网全景图（需截到官网网址链接）
              </div>
              <div class="personalInfo_value global_container_center">
                <div
                  class="personalInfo_image-block"
                  @click="selectFileImg('professional_panoramic')"
                >
                  <CustomImage
                    :src="doctorInfo.professional_panoramic"
                    v-if="doctorInfo.professional_panoramic"
                    alt=""
                  />
                  <div class="personalInfo_image-select" v-else>
                    <el-icon v-show="!doctorInfo.professional_panoramic"
                      ><Plus
                    /></el-icon>
                    <span> 上传官网全景图 </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="personalInfo_block" v-if="doctorInfo.work_type == 1">
              <div class="personalInfo_key">官网医生身份信息截图页</div>
              <div class="personalInfo_value global_container_center">
                <div
                  class="personalInfo_image-block"
                  @click="selectFileImg('professional_person')"
                >
                  <CustomImage
                    :src="doctorInfo.professional_person"
                    v-if="doctorInfo.professional_person"
                    alt=""
                  />
                  <div class="personalInfo_image-select" v-else>
                    <el-icon v-show="!doctorInfo.professional_person"
                      ><Plus
                    /></el-icon>
                    <span> 上传官网医生身份信息截图页 </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="personalInfo_block">
            <div class="personalInfo_key"><span>*</span>行政职务</div>
            <div class="global-input personalInfo_value">
              <el-input
                v-model.trim="doctorInfo.job"
                placeholder="请输入行政职务"
              ></el-input>
            </div>
          </div>
          <div class="personalInfo_block">
            <div class="personalInfo_key">
              <span v-if="militaryHospital == 0">*</span>执业资格证书编码
            </div>
            <div class="global-input personalInfo_value">
              <el-input
                v-model.trim="doctorInfo.professional_qualifications_code"
                placeholder="请输入执业资格证书编码"
              ></el-input>
            </div>
          </div>

          <div class="personalInfo_block">
            <div class="personalInfo_key">
              <span>*</span>专家简历/学术任职
              <!-- <span style="color: #f26649">（该内容将用于制作串场幻灯）</span> -->
            </div>
            <div class="dcotorInfo_textarea personalInfo_value">
              <el-input
                type="textarea"
                :rows="5"
                v-model="doctorInfo.resume_academic"
                placeholder="请输入专家简历/学术任职"
              ></el-input>
            </div>
          </div>
          <div class="personalInfo_block">
            <div class="personalInfo_key">
              <span>*</span>简历图片<span style="color: #f26649"
                >(请上传近期照片，贴合白色轮廓)</span
              >
            </div>
            <div
              class="personalInfo_professional_cover_container global_text_select personalInfo_value"
            >
              <div
                class="personalInfo_professional_cover"
                @click="selectFile('professional_cover')"
              >
                <CustomImage
                  :src="doctorInfo.professional_cover"
                  v-if="isShowField('professional_cover')"
                  alt=""
                />
                <img
                  v-else
                  class="personalInfo_frame"
                  :src="require('@/assets/img/resume_img_have.png')"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div style="color: #f26649; margin-bottom: 8px; font-size: 12.8px">
          专家基本信息必须完成，方可参与项目，生成相关物料
        </div>
        <div class="save_info_button_block">
          <div class="save_info_button" @click.native="doCommit()">保存</div>
        </div>
        <div style="color: #f26649; margin-bottom: 8px; font-size: 12.8px">
          专家协议信息可会后补充，协议信息完整方可生成协议
        </div>
        <div class="personalInfo_block_title">专家协议信息</div>
        <div class="update_personalInfo_block">
          <div class="personalInfo_block">
            <div class="personalInfo_key">手机号</div>
            <div class="global-input personalInfo_value">
              <!-- disabled -->
              <el-input
                v-model.trim="doctorInfo.phone"
                placeholder="请输入手机号"
                clearable
              ></el-input>
            </div>
          </div>
          <div class="personalInfo_block">
            <div class="personalInfo_key">
              身份证号
              <p
                style="color: #f26649"
                v-if="
                  doctorInfo.id_card_ocr_status === 0 &&
                  doctorInfo.id_card_front &&
                  doctorInfo.id_card_reverse &&
                  doctorInfo.id_card_number &&
                  doctorInfo.bank_name_point &&
                  doctorInfo.bank_number &&
                  doctorInfo.bank_province &&
                  doctorInfo.bank_city &&
                  doctorInfo.bank_name
                "
              >
                人工审核中
              </p>
              <p
                style="color: #f26649"
                v-if="
                  doctorInfo.id_card_ocr_status === 2 &&
                  doctorInfo.id_card_front &&
                  doctorInfo.id_card_reverse &&
                  doctorInfo.id_card_number &&
                  doctorInfo.bank_name_point &&
                  doctorInfo.bank_number &&
                  doctorInfo.bank_province &&
                  doctorInfo.bank_city &&
                  doctorInfo.bank_name
                "
              >
                人工审核反馈：{{ doctorInfo.id_card_ocr_reason }}
              </p>
            </div>
            <div class="global-input personalInfo_value">
              <el-input
                v-model.trim="doctorInfo.id_card_number"
                clearable
                @input="inputText('id_card_number')"
                placeholder="请输入身份证号"
              ></el-input>
            </div>
          </div>
          <div class="personalInfo_block">
            <div class="personalInfo_key">身份证照片</div>
            <div>
              <div class="personalInfo_value global_container_center">
                <div
                  class="personalInfo_image-block"
                  @click="selectFile('id_card_front')"
                >
                  <CustomImage
                    :src="doctorInfo.id_card_front"
                    v-if="isShowField('id_card_front')"
                    alt=""
                  />
                  <div class="personalInfo_image-select" v-else>
                    <el-icon v-show="!doctorInfo.id_card_front"
                      ><Plus
                    /></el-icon>
                    <span>{{
                      getShowName("id_card_front", "上传身份证人像面")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="personalInfo_value global_container_center">
                <div
                  class="personalInfo_image-block"
                  @click="selectFile('id_card_reverse')"
                >
                  <CustomImage
                    :src="doctorInfo.id_card_reverse"
                    v-if="isShowField('id_card_reverse')"
                    alt=""
                  />
                  <div class="personalInfo_image-select" v-else>
                    <el-icon v-show="!doctorInfo.id_card_reverse"
                      ><Plus
                    /></el-icon>
                    <span>{{
                      getShowName("id_card_reverse", "上传身份证国徽面")
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 银行账号 -->
          <div>
            <div class="personalInfo_block">
              <div class="personalInfo_key">银行（示例：交通银行）</div>
              <div class="global-input personalInfo_value">
                <el-input
                  v-model.trim="doctorInfo.bank_name"
                  placeholder="请输入银行"
                ></el-input>
              </div>
            </div>
            <div class="personalInfo_block">
              <div class="personalInfo_key">开户行所在省份（示例：湖北）</div>
              <div class="global-input global_text_select personalInfo_value">
                <el-select
                  v-model="doctorInfo.bank_province"
                  placeholder="请选择开户行所在省份"
                  @change="changeProvince('bank')"
                >
                  <el-option
                    v-for="(item, index) in provinces"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="personalInfo_block">
              <div class="personalInfo_key">开户行所在城市（示例：武汉）</div>
              <div class="global-input global_text_select personalInfo_value">
                <el-select
                  v-model="doctorInfo.bank_city"
                  placeholder="请选择开户行所在城市"
                >
                  <el-option
                    v-for="(item, index) in bankCitys"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="personalInfo_block">
              <div class="personalInfo_key">开户银行（xx支行/xx营业部）</div>
              <div class="global-input personalInfo_value">
                <el-input
                  v-model.trim="doctorInfo.bank_name_point"
                  placeholder="请输入开户银行"
                ></el-input>
              </div>
            </div>
            <div class="personalInfo_block">
              <div class="personalInfo_key">银行账号</div>
              <div class="global-input personalInfo_value">
                <el-input
                  v-model.trim="doctorInfo.bank_number"
                  clearable
                  placeholder="请输入银行账号"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="save_info_button_block">
          <div class="save_info_button" @click.native="doCommit('2')">保存</div>
        </div>
      </div>
    </div>
    <!-- </ItemScroll> -->
    <div class="personalInfo_block" style="padding: 0 16px">
      <el-button
        class="submit"
        type="primary"
        @click.native="doCommit('all')"
        round
      >
        <span>提交信息</span>
      </el-button>
    </div>
    <image-cropper
      @receiveData="receiveData"
      ref="imageCropper"
      :CropWidth="getCropperCfg().cropWidth"
      :CropHeight="getCropperCfg().cropHeight"
      :flexScale="getCropperCfg().flexScale"
      :MaxSize="getCropperCfg().MaxSize"
      :isShowHeadimgCalibration="getCropperCfg().isShowHeadimgCalibration"
      :uploadUrl="getCropperCfg().uploadUrl"
      v-if="cropperManager.url"
    ></image-cropper>
    <Popup v-if="showPopup" :msg="config.msg" @doClose="doClose">
      <div class="doctor-popup-main">
        <div class="doctor-popup-content global_container_center">
          {{ config.msg.content }}
        </div>
        <div class="popup-button-group">
          <CustomButton size="small" type="info" @click="showPopup = false"
            >点击补充</CustomButton
          >
          <CustomButton size="small" @click="applyAudit"
            >我上传的信息齐全，申请人工审核</CustomButton
          >
        </div>
      </div>
    </Popup>
    <input
      type="file"
      v-show="false"
      v-if="cropperManager.resetFile"
      @change="changeFile"
      ref="selectfiles"
    />
    <input
      type="file"
      v-show="false"
      v-if="resetFile"
      @change="changeFileImg"
      ref="selectFileImg"
    />
  </div>
</template>
<script>
import ItemScroll from "@/components/unit/ItemScroll";
import ImageCropper from "@/components/unit/ImageCropper";
import Popup from "@/components/unit/Popup.vue";
import CustomImage from "@/components/unit/CustomImage.vue";
import CustomButton from "@/components/unit/CustomButton.vue";
import uploads from "@/common/uploads";
import { Plus } from "@element-plus/icons";
import PopopInstance from "@/components/unit/PopupInstance";
import weixin from "@/common/weixin";
export default {
  name: "personalInfo",
  components: {
    ItemScroll,
    ImageCropper,
    CustomButton,
    CustomImage,
    Popup,
    Plus,
  },

  data() {
    return {
      hospital: [],
      config: {
        cropperCfg: {
          professional_cover: {
            cropWidth: 225,
            cropHeight: 225,
            flexScale: [1, 1],
            MaxSize: 2,
            isShowHeadimgCalibration: true,
            uploadUrl: "https://files.medflying.com/file_system/filesbase64",
          },
          other: {
            cropWidth: 300,
            cropHeight: 189,
            flexScale: [8.56, 5.4],
            MaxSize: 2,
            isShowHeadimgCalibration: false,
            uploadUrl: "https://files.medflying.com/file_system/filesbase64",
          },
        },
        msg: {
          title: "提示",
          hide: true,
          align: "center",
        },
        titles: ["主治医师", "副主任医师", "主任医师"], // 职称列表
        citys: [],
        workType: ["医生ID卡", "官网医生身份信息截图页"],
      },
      resetFile: true,
      selectKey: "",
      showPopup: false,
      hideField: [
        // "phone",
        "id_card_number",
        "bank_number",
        "id_card_front",
        "id_card_reverse",
        // "doctor_work_id_card",
        // "professional_person",
        // "professional_panoramic",
        // "professional_cover",
      ],

      cropperManager: {
        key: "",
        resetFile: true,
        url: "",
      },
      doctors: [],
      doctorInfo: {
        id: "",
        openid: "",
        name: "",
        hospital: "",
        department: "",
        job_name: "",
        province: "",
        city: "",
        work_type: "",
        doctor_work_id_card: "",
        job: "",
        resume_academic: "",
        professional_cover: "",
        professional_person: "",
        professional_panoramic: "",

        phone: "",
        id_card_number: "",
        bank_province: "",
        bank_city: "",
        bank_name: "",
        bank_name_point: "",
        bank_number: "",
        id_card_front: "",
        id_card_reverse: "",
        id_card_ocr_status: "",
        id_card_reason: "",
      },
      loading: false,
      showDoctorList: false,
      phone: "", //对比数据
    };
  },
  watch: {},
  async created() {
    this.config.citys = await this.$tools.getCityConfig();
  },
  mounted() {
    this.id = this.$route.query.id || this.$route.query.user_id || "";
    this.initDoctorData();
    this.initHosData();
    this.$tools.removeTouch();
  },
  computed: {
    provinces() {
      return this.config.citys.map((item) => item.name);
    },
    citys() {
      if (!this.doctorInfo.province) return [];
      let index = this.provinces.indexOf(this.doctorInfo.province);
      if (index == -1) return [];

      return this.config.citys[index].sub.map((item) => item.name);
    },
    bankCitys() {
      if (!this.doctorInfo.bank_province) return [];
      let index = this.provinces.indexOf(this.doctorInfo.bank_province);
      if (index == -1) return [];
      return this.config.citys[index].sub.map((item) => item.name);
    },
    militaryHospital() {
      if (!this.doctorInfo.hospital) return 0;
      let index = this.hospital.filter(
        (v) => v.hospital == this.doctorInfo.hospital
      );
      return index.length;
    },
  },
  methods: {
    async initHosData() {
      this.$axios
        .get(this.$urls.hospital.getmilitaryHospital)
        .then((res) => {
          console.log("res.data :>> ", res.data);
          this.hospital = res.data;
        })
        .catch(() => {});
    },
    async initDoctorData() {
      let url = this.$tools.getURL(this.$urls.sale.userInfo, {
        id: this.id,
      });
      this.$axios
        .get(url, this.getUserId())
        .then((res) => {
          this.doctorInfo = res.data;
          this.phone = res.data.phone;
          this.hideData();
          this.initScroll();
        })
        .catch(() => {});
    },
    getUserId() {
      return {
        "user-id": this.id || this.$store.state.common.userInfo.id,
      };
    },
    changeJobName(e) {
      console.log("e :>> ", e);
    },
    isShowField(key) {
      return this.doctorInfo[key] && !this.doctorInfo[key].includes("*");
    },
    changeProvince(key) {
      if (key == "bank") {
        this.doctorInfo.bank_city = "";
      } else {
        this.doctorInfo.city = "";
      }
    },
    //申请人工审核
    applyAudit() {
      let currLoading = this.$tips.loading();
      this.$axios
        .patch(this.$urls.sale.auditJob, {}, this.getUserId())
        .then((res) => {
          this.$tips.success({ text: "申请人工审核成功" });
          this.initDoctorData();
          currLoading.close();
          this.doClose();
          if (
            this.phone != this.formatInfo().phone &&
            this.$store.state.common.userId
          )
            return this.$router.push({
              path: "/doctorLogin",
            });
          if (this.$route.query.from == "/agreement") {
            if (this.$route.query.type == 1) {
              //系统进
              this.$router.push({
                path: "/doctorInfo",
                query: {
                  ...this.$route.query,
                },
              });
            } else {
              weixin.wx.closeWindow(); //模板进
            }
          } else {
            this.$router.push({
              path: this.$route.query.from,
              query: {
                id: this.id,
                coursewareId: this.$route.query.coursewareId,
                meet_id: this.$route.query.meet_id,
              },
            });
          }
        })
        .catch((err) => {
          this.$tips.error({ text: "申请人工审核失败" });
          console.log(err);
          currLoading.close();
          this.doClose();
        });
    },
    //数据脱敏
    hideData() {
      for (let key of this.hideField) {
        this.doctorInfo[key] = this.doctorInfo[key] || "";
        if (!this.doctorInfo[key]) continue;
        let startLen = 0;
        let endLen = 0;
        switch (key) {
          //手机号保留后4位
          case "phone":
            startLen = 0;
            endLen = -4;
            break;
          //身份证号保留后6位
          case "id_card_number":
            startLen = 0;
            endLen = -6;
            break;
          //银行卡号保留前3位后3位
          case "bank_number":
            startLen = 3;
            endLen = -3;
            break;
          //其他的为链接,保留域名与文件名后3位:http://ioclassimedunion.com********921.png(前台不做处理,显示已上传)
          default:
            startLen = 8;
            endLen = -7;
        }
        this.doctorInfo[key] =
          this.doctorInfo[key].slice(0, startLen) +
          "****" +
          this.doctorInfo[key].slice(endLen);
        console.log(this.doctorInfo[key], key);
      }
    },
    getShowName(key, label) {
      return this.doctorInfo[key] ? "已上传" : label;
    },
    inputText(key) {
      if (this.doctorInfo.id_card_number.includes("****")) {
        this.doctorInfo[key] = "";
      }
    },
    selectFileImg(key, index) {
      this.selectKey = key;
      this.$refs.selectFileImg.click();
    },
    //上传图片(不裁图)
    async changeFileImg(event) {
      let file = event.target.files[0];
      this.resetFile = false;
      let loading = this.$tips.loading();
      this.$nextTick(() => {
        this.resetFile = true;
      });
      try {
        let res = await uploads.uploadFile({
          file,
          reg: "jpg|jpeg|png|PNG|JPG|JPEG",
          key: "files",
        });
        loading.close();
        console.log("res :>> ", res);
        this.doctorInfo[this.selectKey] = res.data[0];
      } catch (err) {
        console.log(err);
        loading.close();
        // this.$tips.error({ text: "上传失败,请重新上传" });
      }
    },

    selectLocalFile() {
      this.$refs.selectfiles.click();
    },
    getCropperCfg() {
      let key =
        this.cropperManager.key == "professional_cover"
          ? "professional_cover"
          : "other";
      return this.config.cropperCfg[key];
    },
    selectFile(key, index) {
      if (this.loading || !this.cropperManager.resetFile) return;
      this.cropperManager.key = key;
      this.selectLocalFile(key);
    },
    changeFile(event) {
      let reg = "jpg|jpeg|png|PNG|JPG|JPEG";
      let file = event.target.files[0];
      if (!file) return;
      let RegObj = new RegExp(`^(${reg})$`);

      let type = file.name.split(".").slice(-1);
      if (!RegObj.test(type)) {
        this.$tips.error({ text: `类型必须是${reg.replace(/\|/g, ",")}` });
        return "";
      }

      const loading = this.$tips.loading();
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (item) => {
        this.cropperManager.url = item.target.result;
        this.cropperManager.resetFile = false;

        this.$nextTick(() => {
          this.cropperManager.resetFile = true;
          this.$refs.imageCropper.setData(this.cropperManager.url);
          setTimeout(() => {}, 2000);
        });
        if (this.cropperManager.url) {
          loading.close();
        }
      };
    },
    receiveData(data) {
      this.doctorInfo[this.cropperManager.key] = data.url;
      this.cropperManager.url = "";
    },
    changeWorkType() {},
    checkData(type) {
      if (!this.doctorInfo.name) {
        this.$tips.error({ text: "请输入姓名" });
        return false;
      }
      if (!this.doctorInfo.hospital) {
        this.$tips.error({ text: "请输入医院" });
        return false;
      }
      if (!this.doctorInfo.department) {
        this.$tips.error({ text: "请输入专家科室" });
        return false;
      }
      // if (!this.doctorInfo.cancer_type) {
      //   this.$tips.error({ text: "请输入主TA" });
      //   return false;
      // }

      if (!this.doctorInfo.province) {
        this.$tips.error({ text: "请选择医生省份" });
        return false;
      }
      if (!this.doctorInfo.city) {
        this.$tips.error({ text: "请选择医生城市" });
        return false;
      }
      if (!this.doctorInfo.job_name) {
        this.$tips.error({ text: "请选择职称" });
        return false;
      }

      // if (
      //   this.doctorInfo.job_name == "医师（住院医师）" &&
      //   !this.doctorInfo.image_into_job
      // ) {
      //   this.$tips.error({ text: "请上传三年以上的临床工作经验证明" });
      //   return false;
      // }
      if (this.doctorInfo.work_type == 0) {
        if (
          !this.doctorInfo.doctor_work_id_card &&
          !this.isHide(this.doctorInfo.doctor_work_id_card)
        ) {
          this.$tips.error({ text: "请上传医生ID卡" });
          return false;
        }
      }
      //没有医生ID卡才必填，有ID卡则选填
      if (
        this.doctorInfo.work_type == 1 &&
        !this.doctorInfo.doctor_work_id_card
      ) {
        if (!this.doctorInfo.professional_panoramic) {
          this.$tips.error({ text: "请上传官网全景图" });
          return false;
        }
        if (!this.doctorInfo.professional_person) {
          this.$tips.error({ text: "请上传官网医生身份信息截图页" });
          return false;
        }
      }
      if (!this.doctorInfo.job) {
        this.$tips.error({ text: "请输入行政职务" });
        return false;
      }
      //不是部队医院 必填  是非必填
      if (this.militaryHospital != 1) {
        if (
          this.doctorInfo.professional_qualifications_code == "" ||
          !/^[\w]*$/.test(this.doctorInfo.professional_qualifications_code)
        ) {
          // this.doctorInfo.professional_qualifications_code = "";
          this.$tips.error({ text: "请输入执业资格证书编码" });
          return false;
        }
      }
      if (this.militaryHospital == 1) {
        if (
          this.doctorInfo.professional_qualifications_code &&
          !/^[\w]*$/.test(this.doctorInfo.professional_qualifications_code)
        ) {
          // this.doctorInfo.professional_qualifications_code = "";
          this.$tips.error({ text: "请输入执业资格证书编码" });
          return false;
        }
      }

      if (!this.doctorInfo.resume_academic) {
        this.$tips.error({ text: "请输入专家简历/学术任职" });
        return false;
      }
      if (this.doctorInfo.resume_academic.length < 10) {
        this.$tips.error({ text: "专家简历/学术任职最少十个字" });
        return false;
      }
      if (!this.doctorInfo.professional_cover) {
        this.$tips.error({ text: "请上传简历图片" });
        return false;
      }

      // if (!this.doctorInfo.phone.includes("****")) {
      if (
        this.doctorInfo.phone &&
        !/^1[3-9]\d{9}$/.test(this.doctorInfo.phone)
      ) {
        this.$tips.error({ text: "请输入格式正确的手机号" });
        return false;
      }
      // }
      if (!this.doctorInfo.id_card_number.includes("****")) {
        let reg =
          /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
        if (
          this.doctorInfo.id_card_number &&
          !reg.test(this.doctorInfo.id_card_number)
        ) {
          this.$tips.error({ text: "身份证号不合法" });
          return false;
        }
      }
      if (!this.doctorInfo.bank_number.includes("****")) {
        if (
          this.doctorInfo.bank_number &&
          !/(^\d{16}$)|(^\d{17}$)|(^\d{18}$)|(^\d{19}$)/.test(
            this.doctorInfo.bank_number
          )
        ) {
          this.$tips.error({ text: "错误的银行账号" });
          return false;
        }
      }

      //以下信息可在在会后补充
      if (this.$route.query.type || type == 1) {
        // if (!this.doctorInfo.phone.includes("****")) {
        if (!this.doctorInfo.phone) {
          this.$tips.error({ text: "请输入手机号" });
          return false;
        }
        if (!/^1[3-9]\d{9}$/.test(this.doctorInfo.phone)) {
          this.$tips.error({ text: "请输入格式正确的手机号" });
          return false;
        }
        // }
        if (!this.doctorInfo.id_card_number.includes("****")) {
          if (!this.doctorInfo.id_card_number) {
            this.$tips.error({ text: "请输入身份证号" });
            return false;
          }
          let reg =
            /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
          if (!reg.test(this.doctorInfo.id_card_number)) {
            this.$tips.error({ text: "身份证号不合法" });
            return false;
          }
        }
        if (!this.doctorInfo.id_card_front) {
          this.$tips.error({ text: "请上传身份证人像面" });
          return false;
        }
        if (!this.doctorInfo.id_card_reverse) {
          this.$tips.error({ text: "请上传身份证国徽面" });
          return false;
        }
        if (!this.doctorInfo.bank_name) {
          this.$tips.error({ text: "请输入银行" });
          return false;
        }
        if (!this.doctorInfo.bank_province) {
          this.$tips.error({ text: "请选择银行省份" });
          return false;
        }
        if (!this.doctorInfo.bank_city) {
          this.$tips.error({ text: "请选择银行城市" });
          return false;
        }
        if (!this.doctorInfo.bank_name_point) {
          this.$tips.error({ text: "请输入开户银行" });
          return false;
        }
        if (!this.doctorInfo.bank_number.includes("****")) {
          if (!this.doctorInfo.bank_number) {
            this.$tips.error({ text: "请输入银行账号" });
            return false;
          }
          if (
            !/(^\d{16}$)|(^\d{17}$)|(^\d{18}$)|(^\d{19}$)/.test(
              this.doctorInfo.bank_number
            )
          ) {
            this.$tips.error({ text: "错误的银行账号" });
            return false;
          }
        }
      }
      return true;
    },

    initScroll(status = false) {
      this.$nextTick(() => {
        // this.$refs.ItemScroll.initScroll(true);
      });
    },
    bgChange() {
      // this.initScroll();
    },

    isHide(data) {
      return (String(data) || "").includes("*");
    },

    formatInfo() {
      let data = { ...this.doctorInfo };
      for (let key in data) {
        if (data[key] === null) data[key] = "";
      }
      return data;
    },
    clearInfo(){
      
      this.$tools.setStorage("userLogin", {},localStorage);
      this.$store.dispatch("setItem", { userId: 0 });
      this.$store.dispatch("setItem", { userInfo: {} });
    },
    jumpLogin(){
      let msg = {
        title: "温馨提示",
        html: `<div class="popup-content-main">检测到您的账号已修改，请用最新的账号重新登录系统！</div>`,
        btngroup: { sure: "确认" },
        align: "center",
      };
      PopopInstance(msg).then((res) => {
        
        this.clearInfo()
        this.$router.push({
          path: "/doctorLogin",
        });
    });
    },
    //提交医生信息
    doCommit(type = 0) {
      // console.log("this.formatInfo() :>> ", this.formatInfo());
      // return;
      if (!this.checkData(type) || this.loading) return;
      this.loading = true;
      let currLoading = this.$tips.loading();
      this.$axios
        .patch(this.$urls.sale.userInfo, this.formatInfo(), this.getUserId())
        .then((res) => {
          let msg = {};
          setTimeout(() => {
            switch (res.code) {
              case 2002:
                this.config.msg.content = res.msg;
                this.showPopup = true;
                break;
              case 2001:
                msg = {
                  title: "温馨提示",
                  html: `<div class="popup-content-main">身份信息审核不通过，请联系会议执行人员进行人工审核！</div>`,
                  btngroup: { sure: "确认" },
                  align: "center",
                };
                PopopInstance(msg).then((res) => {
                  if (
                    this.phone != this.formatInfo().phone &&
                    this.$store.state.common.userId
                  )
                    this.jumpLogin()
                  //一定是专家
                  if (this.$route.query.from == "/agreement") {
                    if (this.$route.query.type == 1) {
                      //系统进
                      this.$router.push({
                        path: "/doctorInfo",
                        query: {
                          ...this.$route.query,
                        },
                      });
                    } else {
                      weixin.wx.closeWindow(); //模板进
                    }
                  } else {
                    this.$router.push({
                      path: this.$route.query.from,
                      query: {
                        ...this.$route.query,
                      },
                    });
                  }
                });
                break;
              default:
                if (!this.doctorInfo.professional_qualifications_code) {
                  this.$tips.success({ text: "修改成功" }, 1000);
                  if (
                    this.phone != this.formatInfo().phone &&
                    this.$store.state.common.userId
                  )
                    this.jumpLogin()
                  // if (type == "all") {
                  this.loading = false;
                  this.$router.push({
                    path: this.$route.query.from,
                    query: {
                      ...this.$route.query,
                    },
                  });
                  // }
                } else {
                  msg = {
                    title: "温馨提示",
                    html: `<div class="popup-content-main">执业医师资格证编码不正确将会影响付款</div>`,
                    btngroup: { close: "取消", sure: "确认" },
                    align: "center",
                  };
                  PopopInstance(msg)
                    .then((res) => {
                      setTimeout(() => {
                        this.$tips.success({ text: "修改成功" }, 1000);
                        if (
                          this.phone != this.formatInfo().phone &&
                          this.$store.state.common.userId
                        )
                          this.jumpLogin()
                        // if (type == "all") {
                        this.loading = false;
                        this.$router.push({
                          path: this.$route.query.from,
                          query: {
                            ...this.$route.query,
                          },
                        });
                        // }
                      }, 500);
                    })
                    .catch(() => {
                      if (
                        this.phone != this.formatInfo().phone &&
                        this.$store.state.common.userId
                      )
                        this.jumpLogin()
                    });
                }
            }
            this.loading = false;
            currLoading.close();
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            this.loading = false;
            currLoading.close();
          }, 1000);
        });
    },

    doClose() {
      this.showPopup = false;
    },
  },
};
</script>
<style>
.personalInfo_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
  background: #f5f5f5;
}

.personalInfo_container .el-input.is-disabled .el-input__inner,
.personalInfo_container .el-textarea.is-disabled .el-textarea__inner {
  background: #fff;
}

.el-textarea__inner {
  font-size: 12.8px;
}

.personalInfo_itemScroll {
  flex: 1 0 0;
  margin-bottom: 8px;
}

.personalInfo_block {
  margin-bottom: 16px;
}

.personalInfo_block .el-button {
  width: 100%;
}
.personalInfo_id_card_type .global-input {
  padding: 0 16px;
}

.personalInfo_key {
  color: #6f6f6f;
  font-size: 12.8px;
}

.personalInfo_key span {
  color: #f26649;
}

.personalInfo_value {
  margin-top: 8px;
  /* padding-right: 8px; */
  display: flex;
  justify-content: space-between;
  color: #6a6266;
  word-break: break-all;
  position: relative;
}

.personalInfo_image-block {
  width: 304px;
  height: 216px;
  background: #fff;
  border: 1px solid #9f9f9f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9f9f9f;
}

.personalInfo_image-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12.8px;
}

.personalInfo_image-select i {
  font-size: 26px;
  line-height: 48px;
}

.disable_background,
.disable_background .el-input.is-disabled .el-input__inner {
  background-color: #cccccc;
  color: #000;
}

.docter_info_button {
  flex: 56px 0 0;
  height: 100%;
  border-radius: 4px;
  font-size: 14.4px;
  line-height: 28px;
  text-align: center;
  border: 1px solid #f26649;
  color: #000;
}

.update_button_group {
  padding: 8px;
  display: flex;
  justify-content: flex-end;
}

.cancle_button {
  margin-right: 16px;
}

.update_button {
  padding: 4px 16px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #f26649;
  color: #000;
}

.personalInfo_container .personalInfo_tips {
  margin: 8px 0;
}

.update_personalInfo_block {
  padding-bottom: 20px;
  border: 1px solid #f26649;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  /* background-color: #a1a1a1; */
}

.save_info_button_block {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.save_info_button {
  padding: 4px 16px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #f26649;
  background: #f26649;
  color: #fff;
  font-size: 16px !important;
  width: 80px;
}

.personalInfo_block_title {
  color: #f26649;
  font-size: 14.4px;
  margin-bottom: 4px;
}

.personalInfo_professional_cover {
  width: 160px;
  height: 160px;
}

.personalInfo_professional_cover img {
  width: 100%;
  border: 1px solid #ccc;
}

.personalInfo_frame {
  width: 100%;
  height: 100%;
}

.personalInfo_title {
  background: #f26649;
  color: #fff;
  padding: 12px 0;
  text-align: center;
  margin-bottom: 16px;
  font-size: 16px;
}

.docter_info_button_mate {
  background: #f26649;
  color: #fff;
  border: none;
}

.personalInfo_container .submit {
  background: #f26649;
  border: none;
  font-size: 16px !important;
}

.personalInfo_container .doctor-popup-main {
  margin: 8px 0 8px 0;
  width: 100%;
}

.personalInfo_container .doctor-popup-content {
  padding: 8px 0;
  font-size: 14.4px;
  text-align: center;
}

.personalInfo_container .popup-button-group {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.personalInfo_container .popup-button-group .customButton_container {
  width: 100%;
  height: 32px;
  font-size: 12.8px !important;
  line-height: 14.4px !important;
  margin-bottom: 10px;
  border-radius: 4px;
}

.doctor-match-list {
  width: 100%;
  height: auto;
  position: absolute;
  right: 0;
  top: 44.8px;
  background: #fff;
  z-index: 1000;
  box-shadow: 0 0 3.2px -1.6px #222;
  padding: 16px 16px 0;
}

.doctor-match-title {
  color: #f26649;
  font-size: 12.8px;
}

.doctor-match-list::before {
  content: "";
  width: 0;
  height: 0;
  border-width: 8px;
  border-color: transparent transparent #fff transparent;
  border-style: solid;
  position: absolute;
  right: 36px;
  top: -16px;
  z-index: 1001;
}

.doctor-match-ul {
  max-height: 144px;
  overflow: scroll;
}

.doctor-match-li {
  height: 24px;
  line-height: 24px;
  font-size: 12.8px;
}
.personalInfo_name .el-input.is-disabled .el-input__inner {
  color: #000;
}
.doctor-match-button {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doctor-match-button .customButton_container {
  width: 64px;
  height: 24px !important;
}
.personalInfo_container .el-textarea__inner {
  overflow: auto;
}
.personalInfo_container .el-input.is-disabled .el-input__wrapper {
  background: #fff !important;
}
.personalInfo_container .el-input__wrapper,
.personalInfo_container .el-select__wrapper {
  padding: 0;
}
</style>
