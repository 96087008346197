<template>
  <div class="register_container register" id="login">
    <div class="register_main">
      <img
        :src="require('@/assets/img/register-title.png')"
        class="register_banner"
      />

      <div class="register_block">
        <div class="register_info_block">
          <div class="register_info_key">
            <span class="global-point">*</span>姓名
          </div>
          <div class="global_text_input register_info_value">
            <el-input
              size="mini"
              v-model.trim="data.name"
              placeholder="请输入姓名"
            ></el-input>
          </div>
        </div>
        <div class="register_info_block">
          <div class="register_info_key">
            <span class="global-point">*</span>手机号
          </div>
          <div class="global_text_input register_info_value">
            <el-input
              :maxlength="11"
              size="mini"
              @input="inputField('phone')"
              v-model.trim="data.phone"
              placeholder="请输入手机号"
            ></el-input>
          </div>
        </div>
        <div class="register_info_block">
          <div class="register_info_key">
            <span class="global-point">*</span>省份
          </div>
          <div class="global_text_input register_info_value">
            <el-select
              v-model="data.province"
              placeholder="请选择省份"
              @change="changeProvince()"
            >
              <el-option
                v-for="(item, index) in provinces"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="register_info_block">
          <div class="register_info_key">
            <span class="global-point">*</span>城市
          </div>
          <div class="global_text_input register_info_value">
            <el-select v-model="data.city" placeholder="请选择城市">
              <el-option
                v-for="(item, index) in citys"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="register_info_block">
          <div class="register_info_key">
            <span class="global-point">*</span>医院
          </div>
          <div class="global_text_input register_info_value">
            <el-input
              size="mini"
              v-model.trim="data.hospital"
              placeholder="请输入医院"
            ></el-input>
          </div>
        </div>
        <div class="register_info_block">
          <div class="register_info_key">
            <span class="global-point">*</span>科室
          </div>
          <div class="global_text_input register_info_value">
            <el-input
              size="mini"
              v-model.trim="data.department"
              placeholder="请输入科室"
            ></el-input>
          </div>
        </div>
        <div class="register_info_block">
          <div class="register_info_key">
            <div class="global-point-none"></div>
            邮箱
          </div>
          <div class="global_text_input register_info_value">
            <el-input
              size="mini"
              @input="inputField('email')"
              v-model.trim="data.email"
              placeholder="请输入邮箱"
            ></el-input>
          </div>
        </div>
        <div class="register_btn">
          <CustomButton
            size="small"
            class="register_submit"
            @click="goToRegister"
          >
            提交</CustomButton
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";
export default {
  name: "register",
  components: {
    CustomButton,
  },
  data() {
    return {
      data: {
        name: "",
        phone: "",
        province: "",
        city: "",
        hospital: "",
        department: "",
        email: "",
        // is_sale: 0, //1志愿者 0 专家
      },
      config: { citys: [] },
      loading: false,
    };
  },
  computed: {
    provinces() {
      return this.config.citys.map((item) => item.name);
    },
    citys() {
      if (!this.data.province) return [];
      let index = this.provinces.indexOf(this.data.province);
      if (index == -1) return [];

      return this.config.citys[index].sub.map((item) => item.name);
    },
  },
  async created() {
    this.config.citys = await this.$tools.getCityConfig();
  },
  methods: {
    formData() {
      if (!this.data.name) {
        this.$tips.error({ text: "请输入姓名" });
        return false;
      }
      if (!this.data.phone) {
        this.$tips.error({ text: "请输入手机号" });
        return false;
      }
      if (!/^1[3-9]\d{9}$/.test(this.data.phone)) {
        this.$tips.error({ text: "请输入正确的手机号" });
        return false;
      }
      if (!this.data.province) {
        this.$tips.error({ text: "请选择省份" });
        return false;
      }
      if (!this.data.city) {
        this.$tips.error({ text: "请选择城市" });
        return false;
      }
      if (!this.data.hospital) {
        this.$tips.error({ text: "请输入医院" });
        return false;
      }
      if (!this.data.department) {
        this.$tips.error({ text: "请输入科室" });
        return false;
      }
      if (this.data.email) {
        if (!/^\w+[.-\w]+@[-\w]+\.\w+$/.test(this.data.email)) {
          this.$tips.error({ text: "邮箱格式不正确！" });
          return false;
        }
      }
      return true;
    },
    inputField(key) {
      switch (key) {
        case "phone":
          this.data[key] = this.data[key].replace(/\D/g, "");
          break;
        case "passwd":
          this.data.passwd = this.data[key].replace(/\D/g, "");
          break;
      }
    },
    changeProvince() {
      this.data.city = "";
    },
    goToRegister() {
      if (!this.formData()) {
        return;
      }
      this.$axios
        .post(this.$urls.login.userAdd, this.data, { "user-id": "" })
        .then((res) => {
          this.data = {};
          this.$tips.success({ text: "提交成功，请审核通过后再登录" });

          setTimeout(() => {
            this.$router.push({
              path: "/doctorLogin",
            });
          }, 2000);
        })
        .catch(() => {});
    },
  },
};
</script>
<style>
.register_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("../../../assets/img/register-bg.png");
  background-size: 100% 110%;
  background-repeat: no-repeat;
  overflow: auto;
  /* background: linear-gradient(180deg, #fff8f8, #fde3f0); */
}
.register_main {
  /* flex: 1 0 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.register .register_banner {
  width: 300px;
  margin-top: 90px;
  object-fit: contain;
  margin-bottom: 0 !important;
}

.register_block {
  width: 90%;
  /* background: #fff; */
}
.register_info_block {
  margin-bottom: 10px;
}
.register_info_key {
  font-size: 14px;
  display: flex;
}
.global-point-none {
  width: 7px;
  height: 14px;
  box-sizing: border-box;
}
.el-select {
  width: 100%;
}
.el-input__inner {
  border: none !important;
}
.register .register_btn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register_btn .register_submit {
  width: 104px;
  height: 30px;
  border-radius: 30px;
  margin-bottom: 20px;
}
</style>
