<template>
  <div class="login_container" id="login">
    <div class="login_main">
      <img :src="require('@/assets/img/title1.png')" class="login_banner" />
      <div class="login_title">登录</div>
      <div class="login_block">
        <el-input
          v-model.trim="data.phone"
          @input="inputField('phone')"
          type="text"
          placeholder="请输入账号"
        >
          <template #prefix>
            <img
              :src="require('@/assets/img/phone.png')"
              class="login_icon"
            /> </template
        ></el-input>
        <div class="login_block_code">
          <el-input
            ref="code"
            @input="inputField('code')"
            v-model.trim="data.code"
            type="text"
            placeholder="请输入验证码"
          >
            <template #prefix>
              <img :src="require('@/assets/img/code.png')" class="login_icon" />
            </template>
            <template class="login_block_input" #suffix>
              <span @click="sendCode">{{
                timer.text || "获取验证码"
              }}</span></template
            >
          </el-input>
        </div>

        <div class="login_btn">
          <CustomButton size="small" class="login_submit" @click="goToLogin">
            登录</CustomButton
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";
export default {
  name: "login",
  components: {
    CustomButton,
  },
  data() {
    return {
      data: {
        phone: "",
        code: "",
        is_sale: 1, //1志愿者 0 专家
      },
      loading: false,
      timer: {
        time: null,
        maxCount: 60000,
        startTime: 0,
        text: "",
      },
    };
  },

  created() {
    this.data = {
      ...this.data,
      ...this.$tools.getStorage("saleLogin", localStorage),
    };
  },
  methods: {
    sendCode() {
      if (this.codeLoading || this.timer.time) return;
      if (!this.data.phone) {
        this.$tips.error({ text: "请输入手机号码" });
        return false;
      }
      if (!/^1[3-9]\d{9}$/.test(this.data.phone)) {
        this.$tips.error({ text: "手机号码不正确" });
        return false;
      }
      this.codeLoading = true;
      this.$axios
        .get(`${this.$urls.login.saleCode}?phone=${this.data.phone}`)
        .then(() => {
          setTimeout(() => {
            this.codeLoading = false;
          }, 1000);
          this.timer.startTime = Date.now();
          this.checkTime();
          this.$tips.success({ text: "发送成功" });
        })
        .catch(() => {
          setTimeout(() => {
            this.codeLoading = false;
          }, 1000);
        });
    },
    checkTime() {
      if (this.timer.time) {
        clearTimeout(this.timer.time);
        this.timer.time = null;
      }
      let curTime = Date.now();
      if (this.timer.startTime + this.timer.maxCount > curTime) {
        this.timer.text = `${Math.floor(
          (this.timer.maxCount - (curTime - this.timer.startTime)) / 1000
        )}s`;
        this.$tools.setStorage("downStartTime", this.timer.startTime);
        this.timer.time = setTimeout(() => {
          this.checkTime();
        }, 1000);
      } else {
        this.timer.startTime = 0;
        (this.timer.text = ""), (this.codeLoading = false);
        this.$tools.setStorage("downStartTime", this.timer.startTime);
      }
    },

    formData() {
      if (!this.data.phone) {
        this.$tips.error({ text: "请输入手机号" });
        return false;
      }
      if (!/^1[3-9]\d{9}$/.test(this.data.phone)) {
        this.$tips.error({ text: "请输入正确的手机号" });
        return false;
      }

      if (!/^(\d{5})?$/.test(this.data.code)) {
        this.$tips.error({ text: "请输入5位数字验证码" });
        return false;
      }
      return true;
    },
    inputField(key) {
      switch (key) {
        case "phone":
          this.data[key] = this.data[key].replace(/\D/g, "");
          break;
        case "code":
          this.data.code = this.data[key].replace(/\D/g, "");
          break;
      }
    },

    goToLogin() {
      if (!this.formData()) {
        return;
      }
      this.$axios
        .post(this.$urls.login.saleLogin, this.data, { "user-id": "" })
        .then((res) => {
          this.$tools.setStorage("saleLogin", this.data, localStorage);
          // this.$store.dispatch("setItem", { userId: res.data.id });
          this.$store.dispatch("setItem", { salesInfo: res.data });
          // this.$tools.setCookie("openid", res.data.openid);
          //清除专家信息
          this.$tools.setStorage("userLogin", {}, localStorage);
          this.$tools.setStorage("userId", {}, localStorage);
          console.log("this.$route.query.form :>> ", this.$route.query.form);
          let path =
            this.$route.query.form == "/adminInfo" ? "/meetInfo" : "/adminMeet";
          this.$router.push({
            path: path,
            query: { ...this.$route.query },
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style>
.login_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("../../../assets/img/login_banner.png");
  background-size: 100% 70%;
  background-repeat: no-repeat;
  /* background: linear-gradient(180deg, #fff8f8, #fde3f0); */
}
.login_main {
  /* flex: 1 0 0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login_banner {
  width: 300px;
  margin-top: 90px;
  object-fit: contain;
  margin-bottom: 0 !important;
}
.login_block {
  width: 90%;
  /* background: #fff; */
}
.login_title {
  margin: 60px 0 40px 0;
  font-size: 18px;
  color: #000;
}
.login_block .el-input__prefix {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-input__suffix {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_block .el-input__suffix-inner {
  margin-right: 20px;
  padding: 0 10px;
  height: 60%;
  background: #eaeaea;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #606060;
}
.el-input::-webkit-input-placeholder {
  line-height: normal !important;
  font-size: 16px !important;
  color: #fff !important;
}
.login_block .el-input__inner {
  border-radius: 30px;
  outline: none;
  border: none;
  padding-left: 2px;
  font-size: 16px;
  /* background: rgba(255, 255, 255, 0.26); */
  color: #000;
}

.login_btn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.login_btn .login_submit {
  width: 104px;
  height: 30px;
  border-radius: 30px;
}

.login_block .el-icon {
  color: #222;
}

.login_block_code {
  position: relative;
  margin-top: 38px;
}
.login_icon {
  width: 14px;
}
.login_container .el-input__wrapper {
  border-radius: 18px;
  border: none;
  box-shadow: none !important;
}
</style>
